import React, {useState} from "react";
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectEventById} from "../../selectors";
import {DefaultItemsCenter, DefaultLine, DefaultPageWrapper, IconWrapper,} from "../../../../components/Main";

import RegistrationImg from "../../assets/stevie-registration-bg.png";
import {CalendarIcon} from "../../../../components/Icons/CalendarIcon";
import {EventStatus, EventTeamRegistationInput, Region} from "../../../../../generated/graphql";
import {TeamName} from "./TeamName";
import {Payment} from "./Payment";
import {Completion} from "./Completion";
import {RegionSelection} from "./RegionSelection";
import spacetime from "spacetime";
import Input from "../../../../components/Input";
import Button from "../../../../components/Button";

enum Page {
  TEAM_NAME,
  REGION,
  PAYMENT,
  COMPLETION,
}

export function EventSignup() {
  const { id } = useParams();
  const event = useSelector(selectEventById)(id);
  const [input, setInput] = useState<EventTeamRegistationInput>({
    name: "Default",
    eventId: event?.id || "",
    paymentIntentId: "paymentIntent",
    region: Region.NorthAmerica,
  });
  const [page, setPage] = useState<Page>(Page.TEAM_NAME);

  if (!event) {
    return null;
  }

  const handleNameChange = (name: string) => {
    setInput({
      name,
      eventId: input.eventId,
      paymentIntentId: input.paymentIntentId,
      region: input.region,
    });
    setPage(Page.REGION);
  };

  const handleRegionChange = (region: Region) => {
    setInput({
      name: input.name,
      eventId: input.eventId,
      paymentIntentId: input.paymentIntentId,
      region,
    });
    setPage(Page.PAYMENT);
  };

  const startsAt = new Date(event.startsAt || '')
  const endsAt = new Date(event.endsAt || '')

  return (
    <>
      {page == Page.COMPLETION ? (
        <Completion event={event} />
      ) : (
        <DefaultPageWrapper>
          <DefaultItemsCenter className="mt-20">
            <div className="flex">
              <img className="h-40 flex items-center" src={RegistrationImg} />
              <div className="content-modal py-2 pl-4 -ml-48">
                <div className="flex items-center">
                  <IconWrapper>
                    <CalendarIcon className="h-6 w-6" />
                  </IconWrapper>
                  <div className="ml-2 color-white-30">EVENT CALENDAR</div>
                </div>
                <div className="flex items-center mt-2">
                  <div className="bg-secondary p-2 rounded-md mr-4">
                    <div className="color-white-60">{spacetime(startsAt).format('{day}, {month-short} {date-ordinal}')}</div>
                    <div className="color-white-70 mb-1">{spacetime(startsAt).format('time')}</div>
                    <div className="color-white-30">EVENT STARTS</div>
                  </div>
                  <div className="bg-secondary p-2 rounded-md mr-4">
                    <div className="color-white-60">{spacetime(endsAt).format('{day}, {month-short} {date-ordinal}')}</div>
                    <div className="color-white-70 mb-1">{spacetime(endsAt).format('time')}</div>
                    <div className="color-white-30">ESTIMATED END</div>
                  </div>
                </div>
              </div>
            </div>
          </DefaultItemsCenter>
          <DefaultItemsCenter className="mt-8">
            {event.status == EventStatus.Registration ? (
              <>
                {page == Page.TEAM_NAME && (
                  <TeamName onComplete={handleNameChange} />
                )}
                {page == Page.PAYMENT && (
                  <Payment
                    event={event}
                    input={input}
                    onComplete={() => setPage(Page.COMPLETION)}
                  />
                )}
                {page == Page.REGION && <RegionSelection onComplete={handleRegionChange} />}
              </>
            ) : (
              <div className="content-modal px-6 py-6 text-center" style={{ width: 420 }}>
                <div className="mx-4">
                  <div className="text-2xl color-white-80">
                    Signups Closed
                  </div>
                  <div className="color-white-50">
                    Sorry but signups for this event have closed.
                  </div>
                </div>
              </div>
            )}
          </DefaultItemsCenter>
        </DefaultPageWrapper>
      )}
    </>
  );
}
