/**
 *
 * Events
 *
 */

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import { reducer, sliceKey, actions } from "./slice";
import {selectEvents, selectRegisteredEvents, selectTeamForEvent} from "./selectors";
import { eventsSaga } from "./saga";

interface Props {}

export function Events(props: Props) {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: eventsSaga });

  const registeredEvents = useSelector(selectRegisteredEvents);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.fetchEventsStart());
  }, []);

  return <></>;
}
