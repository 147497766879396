import {
  DefaultItemsCenter,
  DefaultPageWrapper,
} from "../../../../components/Main";
import PubBg from "../../assets/pub.png";
import React, { useState } from "react";
import Input from "../../../../components/Input";
import { DoubleChevronRightIcon } from "../../../../components/Icons/DoubleChevronRightIcon";
import { actions } from "../../slice";
import { useDispatch } from "react-redux";
import { apiSdk } from "../../../../../utils/graphqlclient";

export default function PubTeamLobbyCreation() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [teamName, setTeamName] = useState<string>("");
  const dispatch = useDispatch();

  const createTeam = async () => {
    setError("");
    setIsLoading(true);
    try {
      const team = await apiSdk.createPubTeamLobby({ name: teamName });
      dispatch(actions.fetchPubLobbyEnd(team.createPubTeamLobby));
    } catch (e) {
      setError("Error creating the public lobby, please try again.");
    }
    setIsLoading(false);
  };

  return (
    <DefaultPageWrapper>
      <DefaultItemsCenter className="mt-20">
        <div className="flex-col flex">
          <div>
            <img src={PubBg} className="z-0" style={{ width: 620 }} />
          </div>
          <div
            className="content-modal -mt-40 z-50 py-8"
            style={{ width: 620 }}
          >
            <div className="flex justify-center px-8">
              <div className="w-1/2 text-left">
                <div className="text-2xl font-semibold color-white-70">
                  NEW TEAM
                </div>
                <Input
                  className="w-48 mt-2 mb-4"
                  label="TEAM NAME"
                  value={teamName}
                  onChange={(e) => setTeamName(e.target.value)}
                  maxLength={20}
                />
                <button
                  disabled={isLoading || teamName.length < 1}
                  className="bg-button py-0.5 px-3 color-white-60 rounded flex items-center"
                  onClick={createTeam}
                >
                  <DoubleChevronRightIcon className="h-5 w-5 mr-1" />
                  <div>{isLoading ? "LOADING" : "CREATE"}</div>
                </button>
                {!!error && (
                  <div className="text-sm text-red-500 mt-2">{error}</div>
                )}
              </div>
              <div className="w-1/2 text-left">
                <div className="text-2xl font-semibold color-white-70">
                  DESCRIPTION
                </div>
                <div className="color-white-50">
                  Public matches are RustLounge matches against a random team
                  that can be played at anytime, outside of an event. Your stats
                  in public matches are separated from your event stats
                </div>
              </div>
            </div>
          </div>
        </div>
      </DefaultItemsCenter>
    </DefaultPageWrapper>
  );
}
