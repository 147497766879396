import { EditIcon } from "../../../../components/Icons/EditIcon";
import React, {useEffect, useState} from "react";
import { PubTeamLobby } from "../../../../../generated/graphql";
import { IconWrapper } from "../../../../components/Main";
import { MembersIcon } from "../../../../components/Icons/MembersIcon";
import UserListItem from "../../../../components/UserListItem";
import { UserAddIcon } from "../../../../components/Icons/UserAddIcon";
import { MatchmakingIcon } from "../../../../components/Icons/MatchmakingIcon";
import { ExpandIcon } from "../../../../components/Icons/ExpandIcon";
import {apiSdk} from "../../../../../utils/graphqlclient";
import {useDispatch} from "react-redux";
import {actions} from "../../slice"

interface Props {
  lobby: PubTeamLobby;
}

export function PubTeamMatchmaking({ lobby }: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const dispatch = useDispatch()

  return <div className="content-modal p-5" style={{ width: 400 }}>
    <div className="flex items-center">
      <IconWrapper>
        <MatchmakingIcon className="h-6 w-6" />
      </IconWrapper>
      <div className="ml-2 text-lg color-white-60">Matchmaking</div>
    </div>
    <div className="mt-3">
      <div className="flex bg-secondary py-2 px-4 items-center rounded">
        <div className="flex-grow color-white-70">
          Matchmaking closed for event.
        </div>
      </div>
    </div>
  </div>

  const queueName = () => {
    switch (lobby.members?.length) {
      case 1:
        return "solos";
      case 2:
        return "duos";
      case 3:
        return "trios";
      default:
        return "solos";
    }
  };

  useEffect(() => {
    if (lobby.inMatchmaking) {
      setIsLoading(true)
    }
  }, [lobby.inMatchmaking])

  const joinQueue = async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true)
    const {joinMatchmakingWithPubTeamLobby} = await apiSdk.joinMatchmakingWithPubTeamLobby({id: lobby.id})
    dispatch(actions.fetchPubLobbyEnd(joinMatchmakingWithPubTeamLobby))
  }

  return (
    <div className="content-modal p-5" style={{ width: 400 }}>
      <div className="flex items-center">
        <IconWrapper>
          <MatchmakingIcon className="h-6 w-6" />
        </IconWrapper>
        <div className="ml-2 text-lg color-white-60">Matchmaking</div>
      </div>
      <div className="mt-3">
        <div className="flex bg-secondary py-2 px-4 items-center rounded">
          <div className="flex-grow color-white-70">
            {lobby.inMatchmaking ? (
              <div>
                Looking for match
              </div>
            ) : (
              <div>
                Join the{" "}
                <span className="font-bold color-white-80">{queueName()}</span>{" "}
                queue:
              </div>
            )}
          </div>
          <div>
            <button className="bg-button rounded px-3 py-2 text-sm color-white-70 flex items-center">
              <ExpandIcon className="h-5 w-5 mr-1" />
              <div onClick={joinQueue} className="leading-none">{isLoading ? 'JOINING QUEUE' : 'FIND A MATCH'}</div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
