import { put, takeEvery } from "redux-saga/effects";
import { actions } from "./slice";
import axios from "axios";
import { buildLiveMatchFromResponse } from "../../../types/LiveMatch";
import {apiSdk} from "../../../utils/graphqlclient";

export function* fetchMatch(action: { payload: string; type: string }) {
  const {getMatch} = yield apiSdk.getMatch({id: action.payload})
  yield put(actions.fetchMatchEnd(getMatch));
}

export function* liveMatchSaga() {
  yield takeEvery(actions.fetchMatchStart.type, fetchMatch);
}
