import {
  DefaultItemsCenter,
  DefaultPageWrapper,
  IconWrapper,
} from "../../../../components/Main";
import React from "react";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {selectEventById} from "../../selectors";
import SplashBg from "../../assets/solos-splash.png"
import {selectIsLoggedIn} from "../../../User/selectors";
import {useHistory} from "react-router-dom"
import {CalendarIcon} from "../../../../components/Icons/CalendarIcon";
import Rad from "../../assets/rad.png"
import spacetime from "spacetime";
import {HelpIcon} from "../../../../components/Icons/HelpIcon";
import {LockIcon} from "../../../../components/Icons/LockIcon";

export default function EventLanding() {
  const { id } = useParams();
  const event = useSelector(selectEventById)(id)
  const isAuthed = useSelector(selectIsLoggedIn)
  const history = useHistory()
  const dispatch = useDispatch()

  if (!event) {
    return null
  }

  const redirect = async () => {
    if (!isAuthed) {
      await localStorage.setItem("redir", `/event/${id}/signup`)
    }
    history.push(`/event/${id}/signup`)
  }

  const startsAt = new Date(event.startsAt || '')
  const endsAt = new Date(event.endsAt || '')

  const openCharityLink = () => {
    window.open("https://www.youarerad.org", "_blank")
  }

  return (
    <DefaultPageWrapper>
      <DefaultItemsCenter className="mt-20">
        <div>
          <div className="cursor-pointer" onClick={redirect} style={{width: 580}}>
            <img src={SplashBg}/>
          </div>
          <div className="mt-3 content-modal p-4" style={{width: 580}}>
            <div className="flex items-center">
              <IconWrapper>
                <CalendarIcon className="h-6 w-6" />
              </IconWrapper>
              <div className="ml-2 color-white-30">EVENT DATE</div>
            </div>
            <div className="flex items-center mt-2">
              <div className="bg-secondary p-2 px-4 rounded-md mr-4">
                <div className="color-white-60">{spacetime(startsAt).format('{day}, {month-short} {date-ordinal}')}</div>
                <div className="color-white-70 mb-1">{spacetime(startsAt).format('time')}</div>
                <div className="color-white-30">EVENT STARTS</div>
              </div>
              <div className="bg-secondary p-2 px-4 rounded-md mr-4">
                <div className="color-white-60">{spacetime(endsAt).format('{day}, {month-short} {date-ordinal}')}</div>
                <div className="color-white-70 mb-1">{spacetime(endsAt).format('time')}</div>
                <div className="color-white-30">ESTIMATED END</div>
              </div>
            </div>
          </div>
          <div className="mt-3 py-4 content-modal" style={{width: 580}}>
            <div className="flex px-4">
              <div className="bg-secondary rounded w-8/12 p-3 mr-4">
                <div className="color-white-50">Total Current Pool</div>
                <div className="text-2xl color-white-70 my-1">${event.prizePool} USD</div>
                <div className="text-sm color-white-50">Pool size will grow based<br/> on amount of signups.</div>
              </div>
              <div className="bg-secondary rounded w-full p-2">
                <img className="h-12 cursor-pointer" src={Rad} onClick={openCharityLink} />
                <div className="px-2 text-sm color-white-50 mt-1">All money raised from this event, minus the operating expenses, will be donated directly to Rise Above The Disorder.</div>
              </div>
            </div>
          </div>
          <div className="mt-3 content-modal p-4" style={{width: 580}}>
            <div className="flex items-center color-white-70">
              <IconWrapper>
                <HelpIcon className="h-6 w-6" />
              </IconWrapper>
              <div className="ml-2 text-lg">Event Video</div>
            </div>
            <div className="mt-3">
              <iframe width="550" height="380"  src="https://www.youtube.com/embed/xBFC0_VR73Y" frameBorder="0"
                      allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen/>
            </div>
            </div>

          <div className="mt-3 content-modal p-4" style={{width: 580}}>
            <div className="flex items-center color-white-70">
              <IconWrapper>
                <CalendarIcon className="h-6 w-6" />
              </IconWrapper>
              <div className="ml-2 text-lg">Event Format</div>
            </div>
            <div className="bg-secondary p-3 mt-3">
              <div className="color-white-50 leading-5">{event.format}</div>
            </div>
          </div>

          <div className="mt-3 content-modal p-4" style={{width: 580}}>
            <div className="flex items-center color-white-70">
              <IconWrapper>
                <LockIcon className="h-6 w-6" />
              </IconWrapper>
              <div className="ml-2 text-lg">Event Integrity</div>
            </div>
            <div className="bg-secondary p-3 mt-3">
              <div className="color-white-50 leading-5">Verifying match integrity is extremely important to RustLounge. That's why we have several systems in place to catch cheaters. RustLounge uses automated server-side systems to detect potential cheaters, and employs several experienced Rust admins to manually review matches. In addition, RustLounge stores data on every single match, so our staff can review matches even after they've already completed. If a user is caught using cheats in any RustLounge match they will be permanently banned and blacklisted from accessing RustLounge.</div>
            </div>
          </div>
        </div>
      </DefaultItemsCenter>
    </DefaultPageWrapper>
  );
}
