import {DefaultLine} from "../../../../components/Main";
import NaImg from "../../assets/na.png";
import SwedenImg from "../../assets/sweden.png";
import Button from "../../../../components/Button";
import React, {useState} from "react";
import {Region} from "../../../../../generated/graphql";

interface Props {
  onComplete: (region: Region) => any;
}

export function RegionSelection({ onComplete }: Props) {
  const [region, setRegion] = useState<Region>(Region.NorthAmerica);
  return (
    <div className="content-modal px-6 py-6 text-center" style={{ width: 420 }}>
      <div className="mx-4">
        <div className="text-2xl color-white-80">
          What server region is nearest?
        </div>
        <div className="color-white-50">
          Your matches will be played on servers
          <br /> hosted in the region you select.
        </div>
      </div>
      <DefaultLine className="my-5" />
      <div className="px-16">
        <div className="flex items-center justify-center">
          <div
            className="bg-secondary rounded-lg justify-center mx-2 w-24 h-32 cursor-pointer"
            style={{
              border:
                region == Region.NorthAmerica ? "2px solid rgba(255, 255, 255, 0.5)" : "none",
            }}
            onClick={() => setRegion(Region.NorthAmerica)}
          >
            <div className="bg-support rounded-full mt-3 h-12 w-12 flex items-center mx-auto justify-center mb-3">
              <img className="h-8 w-8" src={NaImg} />
            </div>
            <div className="color-white-50">
              North
              <br />
              America
            </div>
          </div>

          <div
            className="bg-secondary rounded-lg justify-center mx-2 w-24 h-32 cursor-pointer"
            style={{
              border:
                region == Region.Sweden
                  ? "2px solid rgba(255, 255, 255, 0.5)"
                  : "none",
            }}
            onClick={() => setRegion(Region.Sweden)}
          >
            <div className="bg-support rounded-full mt-3 h-12 w-12 flex items-center mx-auto justify-center mb-3">
              <img className="h-8 w-8" src={SwedenImg} />
            </div>
            <div className="color-white-50 mt-auto">
              Sweden
              <br />
              (Europe)
            </div>
          </div>
        </div>
        <Button
          disabled={!region}
          onClick={() => onComplete(region)}
          className="px-10 mt-5"
        >
          Continue
        </Button>
      </div>
    </div>
  );
}
