/**
 *
 * User
 *
 */

import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import { reducer, sliceKey, actions } from "./slice";
import { userSaga } from "./saga";

interface Props {}

export function User(props: Props) {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: userSaga });


  return <></>;
}
