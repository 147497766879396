import { RouteProps, Route } from "react-router-dom";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions, reducer, sliceKey } from "./containers/User/slice";
import { useInjectReducer, useInjectSaga } from "../utils/redux-injectors";
import { userSaga } from "./containers/User/saga";
import {DefaultPageWrapper} from "./components/Main";
import {selectIsInitialLoadComplete, selectIsLoggedIn} from "./containers/User/selectors";

type Props = Omit<RouteProps, "component"> & {
  component: React.ComponentType<any>;
};

function useInitialLoad() {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: userSaga });
  const isAuthenticated = useSelector(selectIsLoggedIn);
  const initialLoadFinished = useSelector(selectIsInitialLoadComplete);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.fetchInitialStart());
  }, [dispatch]);
  return [initialLoadFinished, isAuthenticated];
}

export function PrivateRoute({ component: Component, ...props }: Props) {
  const [initialLoadFinished, isAuthenticated] = useInitialLoad();
  const displayComponent = (childProps) => {
    if (initialLoadFinished) {
      if (isAuthenticated) {
        return <Component {...childProps} />;
      } else {
        window.location.href =
          "https://homebase.rustlounge.gg/login?provider=steam";
        return <DefaultPageWrapper />;
      }
    } else {
      return <DefaultPageWrapper />;
    }
  };
  return <Route {...props} render={displayComponent} />;
}