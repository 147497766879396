import { EditIcon } from "../../../../components/Icons/EditIcon";
import React, {useState} from "react";
import {Team} from "../../../../../generated/graphql";
import Modal from "../../../../components/Modal";
import Input from "../../../../components/Input";
import {DoubleChevronRightIcon} from "../../../../components/Icons/DoubleChevronRightIcon";
import {apiSdk} from "../../../../../utils/graphqlclient";
import {actions} from "../../slice"
import {useDispatch} from "react-redux";

interface Props {
  team: Team;
}

export function EditTeamName({ team }: Props) {
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [error, setError] = useState<string>('');
  const [name, setName] = useState<string>(team.name)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const dispatch = useDispatch()

  const onEditTeamName = async () => {
    setIsLoading(true)
    setError('')
    try {
      const {editTeam} = await apiSdk.editTeam({input: {id: team.id, kit: team.kit, name}})
      dispatch(actions.fetchTeamEnd(editTeam))
      setIsEditing(false)
    } catch {
      setError('Error updating team name, please try again.')
    }
    setIsLoading(false)
  }

  return (
    <>
      <div className="flex bg-secondary py-2 px-4 items-center rounded">
        <div className="flex-grow">
          <div className="leading-none text-sm color-white-40 mb-1">
            TEAM NAME
          </div>
          <div className="leading-none color-white-60">{team.name}</div>
        </div>
        <div>
          <button className="bg-button rounded px-2 py-1 text-sm color-white-60 flex items-center" onClick={() => setIsEditing(true)}>
            <EditIcon className="h-5 w-5 mr-1" />
            <div className="leading-none">EDIT</div>
          </button>
        </div>
      </div>
      {isEditing && (
        <Modal title="Edit team Name" onClose={() => setIsEditing(false)}>
          <div className="">
            <Input label="Team Name" className="w-56 mb-3" maxLength={25} value={name} onChange={(e) => setName(e.target.value)} />
            <button
              disabled={isLoading || name.length < 1}
              onClick={onEditTeamName}
              className="bg-button py-0.5 px-2 color-white-60 rounded flex items-center mt-4"
            >
              <DoubleChevronRightIcon className="h-5 w-5 mr-1" />
              <div>SAVE</div>
            </button>
            {!!error && <div className="text-red-500">{error}</div>}
          </div>
        </Modal>
      )}
    </>
  );
}
