import React, { CSSProperties } from "react";
import { StatefulToolTip } from "react-portal-tooltip";

interface Props {
  active?: boolean;
  children: React.ReactElement[];
  position?: any;
  arrow?: string;
  group?: string;
}
const transition = "opacity 0.3s ease-in-out 0s";

export const baseTooltipStyle = {
  style: {
    background: "#383B40",
    borderColor: "#383B40",
    padding: 0,
    borderRadius: 6,
    color: "rgba(255, 255, 255, 0.7)",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.2)",
    transition,
  } as CSSProperties,
  arrowStyle: {
    color: "#383B40",
    borderColor: "#383B40",
    transition,
  } as CSSProperties,
};

export default function Tooltip({
  children,
  position = "right",
  arrow = "center",
  group,
}: Props) {
  return (
    <StatefulToolTip
      parent={children[0]}
      position={position}
      arrow={arrow}
      group={group}
      style={baseTooltipStyle}
      tooltipTimeout={0}
    >
      <div className="px-2 py-1 text-xs">{children[1]}</div>
    </StatefulToolTip>
  );
}
