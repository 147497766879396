import { useParams } from "react-router-dom";
import React, { useEffect } from "react";

import ProfileBg from "../assets/profile-bg.png";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../slice";
import {
  DefaultItemsCenter,
  DefaultPageWrapper,
} from "../../../components/Main";
import { selectUserById } from "../selectors";
import { UserPresence } from "../../../../generated/graphql";
import { colors } from "../../../../utils/colors";
import { UserAddIcon } from "../../../components/Icons/UserAddIcon";

export default function Profile() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const user = useSelector(selectUserById)(id);

  useEffect(() => {
    dispatch(actions.fetchUserStart(id));
  }, [id]);

  const getColor = () => {
    switch (user?.presence) {
      case UserPresence.InMatch:
        return colors.inmatch;
      case UserPresence.Offline:
        return colors.offline;
      case UserPresence.Online:
        return colors.online;
    }
  };

  return (
    <DefaultPageWrapper>
      <DefaultItemsCenter className="mt-20">
        <div>
          {!!user && (
            <>
              <img className="z-0" src={ProfileBg} style={{ width: 870 }} />
              <div
                className="content-modal -mt-4 py-8 z-50"
                style={{ width: 870 }}
              >
                <div className="flex px-8">
                  <div className="h-32 w-32 bg-secondary rounded-md -mt-20 mr-4 p-2">
                    <img src={user.iconUrl} className="rounded-md" />
                  </div>
                  <div className="flex items-center">
                    <div className="text-2xl color-white-60 mr-3">
                      {user.name}
                    </div>
                    <div
                      className="h-4 w-4 rounded-full mr-56"
                      style={{ backgroundColor: getColor() }}
                    />
                    <button className="bg-secondary rounded-md py-1 px-3 flex items-center color-white-50">
                      <UserAddIcon className="h-6 w-6 mr-2" />
                      <div>Send Friend Request</div>
                    </button>
                  </div>
                </div>
                <div className="flex mt-8 px-8">
                  <div
                    className="bg-secondary rounded-md p-3 overflow-y-scroll"
                    style={{ width: 260, height: 170 }}
                  >
                    <div className="text-xl color-white-60 mb-2">
                      Registered Events
                    </div>
                    <div className="">
                      {user.registeredEvents?.map((event) => (
                        <div className="cursor-pointer bg-content py-2 px-4 rounded-md mb-2">
                          <div className="text-md color-white-50">
                            {event.eventName}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div
                    className="bg-secondary rounded-md mx-auto"
                    style={{ width: 260 }}
                  >
                    <div className="text-2xl color-white-60">Bio</div>
                  </div>
                  <div
                    className="bg-secondary rounded-md"
                    style={{ width: 260 }}
                  >
                    asd
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </DefaultItemsCenter>
    </DefaultPageWrapper>
  );
}
