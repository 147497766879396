import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "types";
import { initialState } from "./slice";
import {Event, UserRegisteredEvent} from "../../../generated/graphql"

const selectDomain = (state: RootState) => state.events || initialState;
const selectRootDomain = (state: RootState) => state || null;

export const selectEvents = createSelector(
  [selectDomain],
  (eventsState) => {
    return Object.keys(eventsState.events).map(k => {
      return eventsState.events[k]
    })
  }
);

export const selectRegisteredEvents = createSelector(
  [selectRootDomain],
  (state) => {
    if (!state || !state.user?.currentUser || !state.events?.events) {
      return null
    }

    const list: UserRegisteredEvent[] = []
    state.user.currentUser.registeredEvents?.map(e => {
      if (state.events?.events[e.eventId]) {
        list.push(e)
      }
    })
    return list
  }
);

export const selectTeamForEvent = createSelector(
  [selectRootDomain],
  (state) => (eventId: string) => {
    if (!state || !state.user?.currentUser || !state.events?.events) {
      return null
    }
    const event = state.user.currentUser.registeredEvents?.find(re => re.eventId == eventId)
    if (event) {
      return state.events.teams[event.teamId]
    } else {
      return null
    }
  }
);

export const selectTeamIdForEvent = createSelector(
  [selectRootDomain],
  (state) => (eventId: string) => {
    if (!state || !state.user?.currentUser || !state.events?.events) {
      return null
    }
    const event = state.user.currentUser.registeredEvents?.find(re => re.eventId == eventId)
    return event?.teamId
  }
);

export const selectEventById = createSelector(
  [selectDomain],
  (eventsState) => (id: string) => {
    if (!eventsState.events[id]) {
      return null
    }
    return eventsState.events[id]
  }
);

export const selectTeamById = createSelector(
  [selectDomain],
  (eventsState) => (id: string) => {
    if (!eventsState.teams[id]) {
      return null
    }
    return eventsState.teams[id]
  }
);
