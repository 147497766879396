import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "types";
import { initialState } from "./slice";

const selectDomain = (state: RootState) => state.user || initialState;

export const selectUserById = createSelector(
  [selectDomain],
  (userState) => (id: string) => userState.users[id] || null
);

export const selectCurrentUser = createSelector(
  [selectDomain],
  (userState) =>  userState.currentUser || null
);

export const selectIsLoggedIn = createSelector(
  [selectDomain],
  (userState) =>  userState.isLoggedIn
);

export const selectIsInitialLoadComplete = createSelector(
  [selectDomain],
  (userState) =>  userState.isInitialLoadComplete
);