import { take, call, put, select, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import {apiSdk} from "../../../utils/graphqlclient";

export function* fetchPubTeam(action: { payload; type: string }) {
  const res = yield apiSdk.getPubTeamLobby({id: action.payload})
  yield put(actions.fetchPubLobbyEnd(res.getPubTeamLobby))
}

export function* lobbiesSaga() {
  yield takeLatest(actions.fetchPubLobbyStart.type, fetchPubTeam);
}
