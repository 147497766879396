import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "utils/@reduxjs/toolkit";
import { ContainerState } from "./types";
import {PubTeamLobby} from "../../../generated/graphql";

// The initial state of the Lobbies container
export const initialState: ContainerState = {
  pubLobby: null
};

const lobbiesSlice = createSlice({
  name: "lobbies",
  initialState,
  reducers: {
    fetchPubLobbyStart(state, action: PayloadAction<string>) {},
    fetchPubLobbyEnd(state, action: PayloadAction<PubTeamLobby>) {
      state.pubLobby = action.payload;
    }
  },
});

export const { actions, reducer, name: sliceKey } = lobbiesSlice;
