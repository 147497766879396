import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "utils/@reduxjs/toolkit";
import { ContainerState } from "./types";

// The initial state of the Notifications container
export const initialState: ContainerState = {
  hasPermission: false
};

function permissionStr() {
  if (!('Notification' in window)) {
    return 'denied';
  }
  return Notification.permission;
}

const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    sendNotification(state, action: PayloadAction<{title: string; body: string}>) {},
    checkNotificationPermissions(state, action: PayloadAction) {
      state.hasPermission = permissionStr() == "granted"
    }
  },
});

export const { actions, reducer, name: sliceKey } = notificationsSlice;
