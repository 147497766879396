/**
 *
 * Notifications
 *
 */

import React from "react";

import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import { reducer, sliceKey } from "./slice";
import { notificationsSaga } from "./saga";

interface Props {}

export function Notifications(props: Props) {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: notificationsSaga });

  return (
    <>
      <div></div>
    </>
  );
}
