import React from "react";
import styled from "styled-components";
import {colors} from "../../../utils/colors";
import tw from "twin.macro";
import Tooltip from "../Tooltip";
import {Link} from "react-router-dom";
import {MatchmakingIcon} from "../Icons/MatchmakingIcon";
import {useSelector} from "react-redux";
import {selectRegisteredEvents} from "../../containers/Events/selectors";
import {selectCurrentUser, selectIsLoggedIn} from "../../containers/User/selectors";

const StyledEventIcon = styled.div`
  ${tw`w-16 h-16 m-4 flex items-center justify-center`}
  transition: all 0.4s;
  border-radius: 14px;
  cursor: pointer;
  &:hover {
    background: ${colors.orange};
  }
`;

export default function Sidebar() {
  const registeredEvents = useSelector(selectRegisteredEvents)
  const isLoggedIn = useSelector(selectIsLoggedIn)
  const user = useSelector(selectCurrentUser)
  if (!isLoggedIn) {
    return null
  }

  return (
    <div className="h-screen w-24 overflow-auto flex-shrink-0" style={{backgroundColor: '#1F2023'}}>
      {!!user?.currentMatchId && (
        <Link to={`/match/${user.currentMatchId}`}>
          <Tooltip>
            <StyledEventIcon className="bg-secondary" style={{background: colors.orange}}>
              <svg
                viewBox="0 0 20 20"
                fill="#fff"
                className="h-10 w-10"
              >
                <path
                  fillRule="evenodd"
                  d="M2 5a2 2 0 012-2h12a2 2 0 012 2v2a2 2 0 01-2 2H4a2 2 0 01-2-2V5zm14 1a1 1 0 11-2 0 1 1 0 012 0zM2 13a2 2 0 012-2h12a2 2 0 012 2v2a2 2 0 01-2 2H4a2 2 0 01-2-2v-2zm14 1a1 1 0 11-2 0 1 1 0 012 0z"
                  clipRule="evenodd"
                />
              </svg>
            </StyledEventIcon>
            <span>Current Match</span>
          </Tooltip>
        </Link>
      )}
      <Link to='/lobbies'>
        <Tooltip>
          <StyledEventIcon className="bg-secondary color-white-60">
            <MatchmakingIcon className="h-10 w-10" />
          </StyledEventIcon>
          <span>Public Lobbies</span>
        </Tooltip>
      </Link>
      <hr className="w-4/6 mx-auto" style={{ borderColor: "#383B40" }} />
      {registeredEvents?.map(e => (
        <Link to={`/event/${e.eventId}`}>
          <Tooltip>
            <StyledEventIcon className="bg-secondary color-white-60 overflow-hidden p-3">
              <img className="w-full h-full" src={e.eventIcon} style={{borderRadius: 8}} />
            </StyledEventIcon>
            <span>{e.eventName}</span>
          </Tooltip>
        </Link>
      ))}
    </div>
  )
}