import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "utils/@reduxjs/toolkit";
import { ContainerState } from "./types";
import {Event, Team} from "../../../generated/graphql"

// The initial state of the Events container
export const initialState: ContainerState = {
  events: {},
  teams: {}
};

const eventsSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    fetchEventsStart(state, action: PayloadAction) {},
    fetchEventsEnd(state, action: PayloadAction<{events: Event[]}>) {
      const {events} = action.payload
      events.map(e => {
        state.events[e.id] = e;
      })
    },
    fetchTeamStart(state, action: PayloadAction<string>) {},
    fetchTeamEnd(state, action: PayloadAction<Team>) {
      state.teams[action.payload.id] = action.payload
    },
  },
});

export const { actions, reducer, name: sliceKey } = eventsSlice;
