import {
  DefaultItemsCenter,
  DefaultLine,
  DefaultPageWrapper,
  IconWrapper,
} from "../../../../components/Main";

import RegistrationImg from "../../assets/registration_bg.png";
import RocketImg from "../../assets/rocket.png";
import { CalendarIcon } from "../../../../components/Icons/CalendarIcon";
import React from "react";
import { Event } from "../../../../../generated/graphql";
import { HelpIcon } from "../../../../components/Icons/HelpIcon";
import { CardIcon } from "../../../../components/Icons/CardIcon";
import {useHistory} from "react-router-dom"
import {useDispatch} from "react-redux";
import {actions} from "../../../User/slice"
import spacetime from "spacetime";

interface Props {
  event: Event;
}

export function Completion({ event }: Props) {
  const history = useHistory();
  const dispatch = useDispatch()

  const onLaunch = () => {
    dispatch(actions.fetchCurrentUserStart())
    history.replace(`/event/${event.id}`)
  }

  const startsAt = new Date(event.startsAt || '')
  const endsAt = new Date(event.endsAt || '')

  return (
    <DefaultPageWrapper>
      <DefaultItemsCenter className="mt-20">
        <div className="flex">
          <div className="mr-5">
            <div className="flex">
              <img className="h-40 flex items-center" src={RegistrationImg} />
              <div className="content-modal py-2 pl-4 -ml-48">
                <div className="flex items-center">
                  <IconWrapper>
                    <CalendarIcon className="h-6 w-6" />
                  </IconWrapper>
                  <div className="ml-2 color-white-30">EVENT CALENDAR</div>
                </div>
                <div className="flex items-center mt-2">
                  <div className="bg-secondary p-2 rounded-md mr-4">
                    <div className="color-white-60">{spacetime(startsAt).format('{day}, {month-short} {date-ordinal}')}</div>
                    <div className="color-white-70 mb-1">{spacetime(startsAt).format('time')}</div>
                    <div className="color-white-30">EVENT STARTS</div>
                  </div>
                  <div className="bg-secondary p-2 rounded-md mr-4">
                    <div className="color-white-60">{spacetime(endsAt).format('{day}, {month-short} {date-ordinal}')}</div>
                    <div className="color-white-70 mb-1">{spacetime(endsAt).format('time')}</div>
                    <div className="color-white-30">ESTIMATED END</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="content-modal w-full mt-5">
              <div className="p-4">
                <div className="flex items-center">
                  <IconWrapper className="mr-3">
                    <CardIcon className="h-6 w-6" />
                  </IconWrapper>
                  <div className="color-white-40 flex-grow">
                    YOUR TEAM IS NOW REGISTERED
                  </div>
                  <div style={{ color: "#208436" }}>ORDER COMPLETE</div>
                </div>
              </div>
              <div className="text-center pb-1">
                <DefaultLine />
                <button onClick={onLaunch} className="bg-support px-3 py-2 color-white-60 rounded-md my-4 flex items-center mx-auto">
                  <div className="rounded-full bg-content flex items-center justify-center p-2 h-8 w-8 mr-3">
                    <img src={RocketImg} />
                  </div>
                  <div>I'M READY, LAUNCH TEAM DASHBOARD</div>
                </button>
              </div>
            </div>
          </div>
          <div className="w-64 content-modal p-3">
            <IconWrapper>
              <HelpIcon className="h-6 w-6" />
            </IconWrapper>
            <div className="px-1">
              <div className="color-white-70 mt-2 text-lg">Have questions?</div>
              <div className="mt-2">
                <div className="color-white-30">PAYMENT SUPPORT</div>
                <div className="color-white-50">
                  For payment questions related to this order, please send an email
                  to support@rustlounge.gg containing your request and order ID.
                </div>
              </div>
              <div className="mt-2">
                <div className="color-white-30">TEAM SUPPORT</div>
                <div className="color-white-50">
                  For questions related to your team for this event, use the RustLounge Discord.
                </div>
              </div>
            </div>
          </div>
        </div>
      </DefaultItemsCenter>
    </DefaultPageWrapper>
  );
}
