import * as ReactDOM from 'react-dom';
import React from "react";
import {XIcon} from "../Icons/XIcon";

interface Props {
  children: any;
  title: string;
  onClose: () => any;
}

export default function Modal({children, onClose, title}: Props) {
  return (
    <Portal>
      <div className="opacity-50 inset-0 fixed z-20">
        <div className="h-screen w-full bg-black flex items-center justify-center z-30">
        </div>
      </div>
      <div className="inset-0 fixed w-full h-full flex items-center justify-center z-50" >
        <div className="bg-secondary rounded-lg max-w-md z-50 flex-none p-6" style={{width: 500}}>
          <div className="flex items-center mb-4" >
            <div className="flex-grow color-white-60 leading-none uppercase text-lg italic">{title}</div>
            <button className="relative bg-button rounded-full text-right color-white-70 flex items-center justify-center" onClick={onClose}>
              <XIcon className="h-5 w-5 m-1" />
            </button>
          </div>
          {children}
        </div>
      </div>
    </Portal>
  )
}

export interface PortalProps {
  children: any;
}

const Portal = ({ children }: PortalProps) => {
  const id = 'modal'
  let root = document.getElementById(id);
  if (!root) {
    root = document.createElement(id);
    root.id = id;
    document.body.appendChild(root);
  }
  return ReactDOM.createPortal(children, root);
};
