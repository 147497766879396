import { colors } from "../../../utils/colors";
import React from "react";
import tw from "twin.macro";
import styled from "styled-components";

export const DefaultPageWrapper = styled.div`
    ${tw`h-screen w-full overflow-y-scroll pb-8`}
    background: ${colors.mainContentBackground}
  `;

export const DefaultItemsCenter = tw.div`w-full flex justify-center`;

export const DefaultLine = styled.div`
  ${tw`w-full`}
  border: 1px solid #383B40;
`;

export const IconWrapper = styled.div`
  ${tw`h-10 w-10 rounded-full flex items-center justify-center`}
  background: #2D2F34;
  color: rgba(255, 255, 255, 0.7);
`;
