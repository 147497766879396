import { DefaultLine } from "../../../../components/Main";
import Input from "../../../../components/Input";
import Button from "../../../../components/Button";
import React, { useState } from "react";

interface Props {
  onComplete: (name: string) => any;
}

export function TeamName({ onComplete }: Props) {
  const [name, setName] = useState<string>("");
  return (
    <div className="content-modal px-6 py-6 text-center" style={{ width: 420 }}>
      <div className="mx-4">
        <div className="text-2xl color-white-80">
          What should we call your team?
        </div>
        <div className="color-white-50">
          Your team name will be public and be
          <br /> used to identify you in this event.
        </div>
      </div>
      <DefaultLine className="my-5" />
      <div className="px-16">
        <Input
          label="Team Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          maxLength={25}
          className="text-left"
        />
        <Button
          disabled={name.length < 1}
          onClick={() => onComplete(name)}
          className="px-10 mt-5"
        >
          Continue
        </Button>
      </div>
    </div>
  );
}
