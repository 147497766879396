import React from "react";
import styled, { keyframes } from "styled-components";
import { colors } from "../../../utils/colors";

interface Props {
  onClick?: () => void;
  height?: number;
  children: any;
  className?: string;
  isLoading?: boolean;
  disabled?: boolean;
  fontSize?: number;
}

const pulse = keyframes`
  0% {
    color: rgba(255, 255, 255, 1);
  }
  50% {
    color: rgba(255, 255, 255, 0.4);
  }
  
  100% {
    color: rgba(255, 255, 255, 1);
  }
`;

const StyledButton = styled.button<Props>`
  background: ${colors.orange};
  border-radius: 30px;
  color: #fff;
  text-align: center;
  transition: all 0.3s;
  font-size: 14px;
  &:hover {
    filter: brightness(85%);
  }
`;

export default function Button({
  onClick,
  children,
  height = 32,
  isLoading,
  className,
  disabled,
  fontSize = 16,
}: Props) {
  return (
    <StyledButton
      className={className}
      onClick={onClick}
      disabled={isLoading || disabled}
      isLoading={isLoading}
      style={{
        height,
        filter: isLoading || disabled ? "brightness(60%)" : undefined,
        fontSize,
      }}
    >
      {!isLoading ? children : "Loading"}
    </StyledButton>
  );
}
