import PubTeamLobbyCreation from "./PubTeamLobbyCreation";
import React from "react";
import { useSelector } from "react-redux";
import { selectPubTeamLobby } from "../../selectors";
import PubTeamLobbyDashboard from "./PubTeamLobbyDashboard";

export default function PubDashboard() {
  const team = useSelector(selectPubTeamLobby);
  return team ? (
    <PubTeamLobbyDashboard lobby={team} />
  ) : (
    <PubTeamLobbyCreation />
  );
}
