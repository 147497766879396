import React, {useState} from "react";
import {Team} from "../../../../../generated/graphql";
import { IconWrapper } from "../../../../components/Main";
import { MatchmakingIcon } from "../../../../components/Icons/MatchmakingIcon";
import { ExpandIcon } from "../../../../components/Icons/ExpandIcon";
import {apiSdk} from "../../../../../utils/graphqlclient";
import {useDispatch} from "react-redux";

interface Props {
  team: Team;
}

export function TeamMatchmaking({ team }: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const dispatch = useDispatch()

  const joinQueue = async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true)
    const {joinMatchmakingWithTeam} = await apiSdk.joinMatchmakingWithTeam({id: team.id})
  }

  if (!team.isMatchmakingEligble) {
    return (
      <div className="content-modal p-5" style={{ width: 400 }}>
        <div className="flex items-center">
          <IconWrapper>
            <MatchmakingIcon className="h-6 w-6" />
          </IconWrapper>
          <div className="ml-2 text-lg color-white-60">Matchmaking</div>
        </div>
        <div className="mt-3">
          <div className="flex bg-secondary py-2 px-4 items-center rounded">
            <div className="flex-grow color-white-70">
              Team ineligible for matchmaking, please wait.
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="content-modal p-5" style={{ width: 400 }}>
      <div className="flex items-center">
        <IconWrapper>
          <MatchmakingIcon className="h-6 w-6" />
        </IconWrapper>
        <div className="ml-2 text-lg color-white-60">Matchmaking</div>
      </div>
      <div className="mt-3">
        <div className="flex bg-secondary py-2 px-4 items-center rounded">
          <div className="flex-grow color-white-70">
            {isLoading ? (
              <div>
                Looking for match
              </div>
            ) : (
              <div>
                Join matchmaking
              </div>
            )}
          </div>
          <div>
            <button className="bg-button rounded px-3 py-2 text-sm color-white-70 flex items-center">
              <ExpandIcon className="h-5 w-5 mr-1" />
              <div onClick={joinQueue} className="leading-none">{isLoading ? 'JOINING QUEUE' : 'FIND A MATCH'}</div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
