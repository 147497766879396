import {useEffect} from 'react';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom'
import {actions as userActions} from '../../containers/User/slice'
import {actions as matchActions} from '../../containers/LiveMatch/slice'

import {selectCurrentUser} from '../../containers/User/selectors';

export default function Websocket() {
  const dispatch = useDispatch()
  const history = useHistory();
  const user = useSelector(selectCurrentUser)

  useEffect(() => {
    if (user) {
      const ws = new WebSocket(`wss://satellite.rustlounge.gg/connect?userId=${user.id}`)

      ws.onopen = () => {
        console.log('Successfully connected to Satellite socket service')
      }

      ws.onmessage = (event) => {
        console.log(event)
        const data = JSON.parse(event.data)
        switch (data.type) {
          case 'match_found': {
            const matchId = data.entityId
            history.push(`/match/${matchId}`)
            dispatch(userActions.fetchCurrentUserStart())
            break;
          }
          case 'match_ready': {
            const matchId = data.entityId
            dispatch(matchActions.fetchMatchStart(matchId))
            break;
          }
          case 'match_complete': {
            const matchId = data.entityId
            dispatch(userActions.fetchCurrentUserStart())
            dispatch(matchActions.fetchMatchStart(matchId))
            break;
          }
        }
      }
    }
  }, [user])

  return (<></>)
}