import { EditIcon } from "../../../../components/Icons/EditIcon";
import React, {useState} from "react";
import {Team, WeaponKit} from "../../../../../generated/graphql";
import Modal from "../../../../components/Modal";
import {DoubleChevronRightIcon} from "../../../../components/Icons/DoubleChevronRightIcon";
import {useDispatch} from "react-redux";
import Select from 'react-select'
import {apiSdk} from "../../../../../utils/graphqlclient";
import {actions} from "../../slice";
import {customStylesForSelector} from "../../../../../utils/colors";

interface Props {
  team: Team;
}

const kitOptions = [
  { value: WeaponKit.AssaultRifle, label: 'Assault Rifle'},
  { value: WeaponKit.Lr, label: 'LR-300'},
  { value: WeaponKit.Mp5, label: 'MP5'},
  { value: WeaponKit.M92, label: 'M92'},
  { value: WeaponKit.M39, label: 'M39'},
  { value: WeaponKit.Sar, label: 'Semi-Automatic Rifle'},
  { value: WeaponKit.Sap, label: 'Semi-Automatic Pistol'},
  { value: WeaponKit.Python, label: 'Python'},
  { value: WeaponKit.Custom, label: 'Custom SMG'},
  { value: WeaponKit.Thompson, label: 'Thompson'},
]

export function EditTeamKit({ team }: Props) {
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [error, setError] = useState<string>('');
  const [kit, setKit] = useState<WeaponKit>(team.kit)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const dispatch = useDispatch()

  const onEditTeamName = async () => {
    setIsLoading(true)
    setError('')
    if (!kit) {
      setError('Select a valid kit.')
      return;
    }
    try {
      const {editTeam} = await apiSdk.editTeam({input: {id: team.id, kit, name: team.name}})
      dispatch(actions.fetchTeamEnd(editTeam))
      setIsEditing(false)
    } catch {
      setError('Error updating team kit, please try again.')
    }
    setIsLoading(false)
  }

  return (
    <div className="flex bg-secondary py-2 px-4 items-center rounded">
      <div className="flex-grow">
        <div className="leading-none text-sm color-white-40 mb-1">TEAM KIT</div>
        <div className="leading-none color-white-60">{team.kit}</div>
      </div>
      <div>
        <button onClick={() => setIsEditing(true)} className="bg-button rounded px-2 py-1 text-sm color-white-60 flex items-center">
          <EditIcon className="h-5 w-5 mr-1" />
          <div className="leading-none">EDIT</div>
        </button>
      </div>
      {isEditing && (
        <Modal title="Edit team kit" onClose={() => setIsEditing(false)}>
          <div className="">
            <Select options={kitOptions} defaultValue={kit} styles={customStylesForSelector} onChange={(e) => setKit(e.value)} />
            <button
              disabled={isLoading}
              onClick={onEditTeamName}
              className="bg-button py-0.5 px-2 color-white-60 rounded flex items-center mt-4"
            >
              <DoubleChevronRightIcon className="h-5 w-5 mr-1" />
              <div>SAVE</div>
            </button>
            {!!error && <div className="text-red-500">{error}</div>}
          </div>
        </Modal>
      )}
    </div>
  );
}
