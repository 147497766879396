import {
  DefaultItemsCenter,
  DefaultPageWrapper,
} from "../../../../components/Main";
import React from "react";
import {useSelector} from "react-redux";
import {selectCurrentUser} from "../../selectors";
import WelcomeBG from "../../assets/welcome-bg.png"
import {selectEvents} from "../../../Events/selectors";

export default function HomePage() {
  const user = useSelector(selectCurrentUser)
  const events = useSelector(selectEvents)

  if (!user) {
    return null;
  }

  return (
    <DefaultPageWrapper>
      <DefaultItemsCenter className="mt-20">
        <div style={{width: 870}}>
          <img src={WelcomeBG} />
          <div className="mt-4 flex">
            <div className="w-8/12 content-modal p-5 mr-4">
              <div className="text-xl color-white-60">Featured Events</div>
              <div className="mt-2">
                {events.map(e => (
                  <div className="bg-secondary p-4 rounded color-white-60 text-lg flex items-center">
                    {e.name}
                  </div>
                ))}

              </div>
            </div>
            <div className="w-6/12 content-modal p-5">
              <div className="text-xl color-white-60">Friends</div>
              <div className="italic mt-2 color-white-50">The friends system will become available on Friday, February 19th</div>
            </div>
          </div>
        </div>
      </DefaultItemsCenter>
    </DefaultPageWrapper>
  );
}
