import { User, UserPresence } from "../../../generated/graphql";
import React from "react";
import { colors } from "../../../utils/colors";

interface Props {
  user: User;
}
export default function UserListItem({ user }: Props) {
  const getColor = () => {
    switch (user.presence) {
      case UserPresence.InMatch:
        return colors.inmatch;
      case UserPresence.Offline:
        return colors.offline;
      case UserPresence.Online:
        return colors.online;
    }
  };

  const getPresence = () => {
    switch (user.presence) {
      case UserPresence.InMatch:
        return "IN MATCH";
      case UserPresence.Offline:
        return "OFFLINE";
      case UserPresence.Online:
        return "ONLINE";
    }
  };

  return (
    <div className="rounded bg-secondary py-2 px-4 flex items-center w-full mb-2">
      <div className="color-white-50 flex-grow">{user.name}</div>
      <div className="text-sm color-white-30 mr-3">{getPresence()}</div>
      <div
        className="h-3 w-3 rounded-full"
        style={{ backgroundColor: getColor() }}
      />
    </div>
  );
}
