import { DefaultLine, IconWrapper } from "../../../../components/Main";
import Button from "../../../../components/Button";
import React, { useEffect, useState } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import {
  StripeCardElementChangeEvent,
  StripeCardElementOptions,
} from "@stripe/stripe-js";
import { EventTeamRegistationInput } from "../../../../../generated/graphql";
import { Event } from "../../../../../generated/graphql";
import { LockIcon } from "../../../../components/Icons/LockIcon";
import { apiSdk } from "../../../../../utils/graphqlclient";
import StripeBg from "../../assets/stripe.png"

interface Props {
  event: Event;
  input: EventTeamRegistationInput;
  onComplete: () => any;
}

const formatPrice = (price: number) => {
  const usFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  return usFormatter.format(price / 100);
};

const CARD_OPTIONS: StripeCardElementOptions = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#c4f0ff",
      color: "rgba(255, 255, 255, 0.8)",
      fontWeight: "600",
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {
        color: "#fce883",
      },
      "::placeholder": {
        color: "rgba(255, 255, 255, 0.5)",
      },
    },
    invalid: {
      iconColor: "#DD4D4D",
      color: "#DD4D4D",
    },
  },
};

export function Payment({ onComplete, event, input }: Props) {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [complete, setComplete] = useState<boolean>(false);

  const [intent, setIntent] = useState<{
    intentId?: string;
    clientSecret?: string;
  }>({});

  useEffect(() => {
    apiSdk.getIntentForEvent({ id: event.id }).then((r) => {
      setIntent({
        intentId: r.getIntentForEvent.intentId,
        clientSecret: r.getIntentForEvent.clientSecret,
      });
    });
  }, [event.id]);

  const processPayment = async () => {
    setIsLoading(true);
    setError("");

    if (!stripe || !elements || !intent.clientSecret || !intent.intentId) {
      setIsLoading(false);
      return null;
    }

    const cardElement = elements.getElement(CardElement);

    if (!cardElement) {
      setIsLoading(false);
      return null;
    }

    try {
      await apiSdk.registerTeamForEvent({
        input: {
          eventId: event.id,
          name: input.name,
          paymentIntentId: intent.intentId,
          region: input.region,
        },
      });
    } catch (e) {
      setError(`Error creating team registration. You have not been charged.`);
      setIsLoading(false);
      return null;
    }

    const confirmation = await stripe.confirmCardPayment(intent.clientSecret, {
      payment_method: { card: cardElement },
    });
    if (confirmation.error) {
      setError(
        confirmation.error.message ||
          "An error occurred when processing your payment. You have not been charged."
      );
      setIsLoading(false);
      return null;
    }
    onComplete();
  };

  const handleCardElementChange = (event: StripeCardElementChangeEvent) => {
    setError(event.error?.message || "");
    setComplete(event.complete);
  };

  return (
    <div className="content-modal px-6 py-6 text-center" style={{ width: 420 }}>
      <div className="">
        <div className="text-2xl color-white-80">Payment</div>
        <div className="color-white-50">
          Your team entry fee powers the event
          <br /> prize-pool and covers event expenses.
        </div>
        <div className="flex mt-5 bg-secondary rounded-md p-2 items-center">
          <IconWrapper>
            <LockIcon className="h-6 w-6" />
          </IconWrapper>
          <div className="text-sm color-white-40 ml-2">
            RustLounge can not access your card details.
          </div>
        </div>
      </div>
      <DefaultLine className="my-5" />
      <div className="px-16">
        <CardElement
          onChange={handleCardElementChange}
          options={CARD_OPTIONS}
        />
        {!!error && <div className="text-red-400 mt-2">{error}</div>}
        <Button
          isLoading={isLoading}
          disabled={!complete}
          onClick={processPayment}
          className="px-10 mt-5"
        >
          Pay - {formatPrice(event.entranceFee)}
        </Button>
      </div>
      <div className="color-white-30 text-xs px-10 mt-4">By pressing Pay you agree for your card to be charged with the amount listed above. All sales are final.</div>
        <img className="h-6 mx-auto mt-4" src={StripeBg} />
    </div>
  );
}
