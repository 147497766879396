export const colors = {
  modalBackground: '#1F2023',
  secondaryBackground: '#25262A',
  mainContentBackground: '#27292D',
  red: '#DD4D4D',
  online: '#208436',
  offline: '#363641',
  inmatch: '#AD5630',
  orange: '#E07547',
  supportAlt: '#2D2F34'
};

export const customStylesForSelector = {
  valueContainer: (provided, state) => ({
    ...provided,
    backgroundColor:'#383B40',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    backgroundColor: '#383B40',
    color: 'rgba(255, 255, 255, 0.3)'
  }),
  group: (provided, state) => ({
    ...provided,
    border: 'none'
  }),
  control: (provided, state) => ({
    ...provided,
    cursor: 'pointer',
    border: 'none',
    boxShadow: 'none',
    backgroundColor: 'none'
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    border: 'none',
    backgroundColor: 'none',
    color: '#fff'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: 'rgba(255, 255, 255, 0.7)'
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    backgroundColor:'#383B40',
    border: 'none',
    color: '#fff'
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? colors.supportAlt : colors.mainContentBackground,
    color: state.isSelected ? 'rgba(255, 255, 255, 0.8)' : 'rgba(255, 255, 255, 0.6)',
    cursor: 'pointer',
    ':active': {
      backgroundColor: colors.supportAlt
    },
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: colors.mainContentBackground,
    color: 'rgba(255, 255, 255, 0.6)'
  })
}