import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "types";
import { initialState } from "./slice";

const selectDomain = (state: RootState) => state.lobbies || initialState;

export const selectPubTeamLobby = createSelector(
  [selectDomain],
  (lobbiesState) => lobbiesState.pubLobby
);
