import { PubTeamLobby } from "../../../../../generated/graphql";
import {
  DefaultItemsCenter,
  DefaultPageWrapper,
  IconWrapper,
} from "../../../../components/Main";
import React, {useEffect} from "react";
import BackgroundImg from "../../assets/pubdashboard.png";
import { SettingsIcon } from "../../../../components/Icons/SettingsIcon";
import { MembersIcon } from "../../../../components/Icons/MembersIcon";
import UserListItem from "../../../../components/UserListItem";
import { UserAddIcon } from "../../../../components/Icons/UserAddIcon";
import { EditPubTeamName } from "./EditPubTeamName";
import { EditPubTeamKit } from "./EditPubTeamKit";
import { PubTeamMatchmaking } from "./PubTeamMatchmaking";
import { EditPubTeamRegion } from "./EditPubTeamRegion";
import {useDispatch, useSelector} from "react-redux";
import {actions} from "../../slice"
import {selectCurrentUser} from "../../../User/selectors";

interface Props {
  lobby: PubTeamLobby;
}

export default function PubTeamLobbyDashboard({ lobby }: Props) {
  const user = useSelector(selectCurrentUser)
  const isLeader = lobby.leaderId == user?.id;
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(actions.fetchPubLobbyStart(lobby.id))
  }, [])

  return (
    <DefaultPageWrapper>
      <DefaultItemsCenter className="mt-20">
        <div className="flex">
          <div className="mr-4">
            <img src={BackgroundImg} style={{ width: 400 }} />
            <div className="content-modal p-5 mt-4" style={{ width: 400 }}>
              <div className="flex items-center mb-3">
                <IconWrapper>
                  <SettingsIcon className="h-6 w-6" />
                </IconWrapper>
                <div className="ml-2 text-lg color-white-60">Team Settings</div>
              </div>
              <EditPubTeamName lobby={lobby} />
              <div className="my-2" />
              <EditPubTeamKit lobby={lobby} />
              <div className="my-2" />
              <EditPubTeamRegion lobby={lobby} />
            </div>
          </div>
          <div>
            <div className="content-modal p-5 mb-4" style={{ width: 400 }}>
              <div className="flex items-center">
                <IconWrapper>
                  <MembersIcon className="h-6 w-6" />
                </IconWrapper>
                <div className="ml-2 text-lg color-white-60">Team Roster</div>
              </div>
              <div className="mt-3">
                {lobby.members?.map((member) => (
                  <UserListItem user={member} />
                ))}
                {!!lobby.members && lobby.members.length < lobby.maxSize && (
                  <div className="rounded bg-secondary py-2 px-4 flex items-center w-full">
                    <UserAddIcon className="color-white-70 h-6 w-6 mr-3" />
                    <div className="color-white-70">
                      Lobby size temp restricted to 1 member
                    </div>
                  </div>
                )}
              </div>
            </div>
            {isLeader && (
              <PubTeamMatchmaking lobby={lobby} />
            )}
          </div>
        </div>
      </DefaultItemsCenter>
    </DefaultPageWrapper>
  );
}
