import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "types";
import { initialState } from "./slice";

const selectDomain = (state: RootState) => state.liveMatch || initialState;

export const selectLiveMatch = createSelector(
  [selectDomain],
  (liveMatchState) => liveMatchState
);

export const selectLiveMatchById = createSelector(
  [selectDomain],
  (state) => (matchId) => state.matches.find((match) => match.id === matchId)
);
