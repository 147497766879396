import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "types";
import { initialState } from "./slice";

const selectDomain = (state: RootState) => state.notifications || initialState;

export const selectHasNotificationPermission = createSelector(
  [selectDomain],
  (notificationsState) => notificationsState.hasPermission
);
