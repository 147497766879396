import {IconWrapper} from "../../../../components/Main";
import {LeaderboardIcon} from "../../../../components/Icons/LeaderboardIcon";
import React, {useEffect, useState} from "react";
import {apiSdk} from "../../../../../utils/graphqlclient";
import {Team} from "../../../../../generated/graphql";

interface Props {
  eventId: string;
  team: Team
}

export default function EventLeaderboard({eventId, team}: Props) {
  const [leaderboard, setLeaderboard] = useState<any>([])

  useEffect(() => {
    e().then(() => {})
  }, [eventId])

  const e = async () => {
    const {getLeaderboardsForEvent} = await apiSdk.getLeaderboardsForEvent({id: eventId})
    setLeaderboard(getLeaderboardsForEvent)
  }

  return (
    <div className="content-modal p-5 mt-4">
      <div className="flex items-center">
        <IconWrapper>
          <LeaderboardIcon className="h-6 w-6" />
        </IconWrapper>
        <div className="flex ml-2 items-center w-full">
          <div className="text-lg color-white-60 flex-grow">Leaderboards</div>
          <div className="italic color-white-50">Ranked: #{team.currentRank}</div>
        </div>
      </div>
      <div className="mt-3 overflow-y-scroll" style={{maxHeight: 200}}>
        {leaderboard.map(e => (
          <div className="flex bg-secondary py-2 px-4 items-center rounded my-1">
            <div className="flex">
              <div className="mr-2 color-white-50">{e.rank}.</div>
              <div className="color-white-70">{e.name}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}