import {PayloadAction} from "@reduxjs/toolkit";
import {createSlice} from "utils/@reduxjs/toolkit";
import {ContainerState} from "./types";
import {User} from "../../../generated/graphql";

// The initial state of the User container
export const initialState: ContainerState = {
  currentUser: undefined,
  users: {},
  isLoggedIn: false,
  isInitialLoadComplete: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    fetchUserStart(state, action: PayloadAction<string>) {},
    fetchUserEnd(state, action: PayloadAction<User>) {
      const user = action.payload
      state.users[user.id] = user
    },
    fetchInitialStart(state, action: PayloadAction) {},
    fetchInitialEnd(state, action: PayloadAction<boolean>) {
      state.isInitialLoadComplete = true;
      state.isLoggedIn = action.payload;
    },
    fetchCurrentUserStart(state, action: PayloadAction) {},
    fetchCurrentUserEnd(state, action: PayloadAction<User>) {
      state.currentUser = action.payload
    },
    storeAuth(state, action: PayloadAction<string>) {},
  },
});

export const { actions, reducer, name: sliceKey } = userSlice;
