import {DefaultPageWrapper} from "../../../components/Main";
import React, {useEffect} from "react";
import {useHistory, useParams} from "react-router-dom"
import {useDispatch} from "react-redux";
import {actions} from "../slice"

export default function Auth() {
  const history = useHistory()
  const dispatch = useDispatch()
  const { token } = useParams();

  useEffect(() => {
    dispatch(actions.storeAuth(token))
    const redir = localStorage.getItem("redir")
    if (redir) {
      history.replace(redir);
      localStorage.removeItem("redir");
    } else {
      history.replace("/");
    }
  }, [])

  return (
    <DefaultPageWrapper />
  )
}