import { GraphQLClient } from 'graphql-request';
import { print } from 'graphql';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

export type Event = {
  __typename?: 'Event';
  id: Scalars['String'];
  teamSize: Scalars['Int'];
  availableKits: Array<Scalars['String']>;
  status: EventStatus;
  entranceFee: Scalars['Int'];
  hostedWith?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  prizePool?: Maybe<Scalars['Int']>;
  startsAt?: Maybe<Scalars['String']>;
  endsAt?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type LeaderboardEntry = {
  __typename?: 'LeaderboardEntry';
  name: Scalars['String'];
  rank: Scalars['Int'];
};

export type LobbyInvite = {
  __typename?: 'LobbyInvite';
  id: Scalars['String'];
  fromName: Scalars['String'];
  name: Scalars['String'];
  type: LobbyInviteType;
};

export type TeamMember = {
  __typename?: 'TeamMember';
  user: User;
  isLeader: Scalars['Boolean'];
};

export type Query = {
  __typename?: 'Query';
  getMyUser: User;
  getFriendRequests?: Maybe<Array<User>>;
  getFriends?: Maybe<Array<User>>;
  getUser: User;
  getEvents?: Maybe<Array<Maybe<Event>>>;
  getEvent?: Maybe<Event>;
  getLeaderboardsForEvent?: Maybe<Array<Maybe<LeaderboardEntry>>>;
  getLobbyInvites?: Maybe<Array<LobbyInvite>>;
  getPubTeamLobby: PubTeamLobby;
  getPubTeamLobbyByInvite: PubTeamLobby;
  getMatch: Match;
  getOrders?: Maybe<Array<Maybe<TeamOrder>>>;
  getIntentForEvent: PaymentIntent;
  getTeam?: Maybe<Team>;
};


export type QueryGetUserArgs = {
  id: Scalars['String'];
};


export type QueryGetEventArgs = {
  id: Scalars['String'];
};


export type QueryGetLeaderboardsForEventArgs = {
  id: Scalars['String'];
};


export type QueryGetPubTeamLobbyArgs = {
  id: Scalars['String'];
};


export type QueryGetPubTeamLobbyByInviteArgs = {
  inviteId: Scalars['String'];
};


export type QueryGetMatchArgs = {
  id: Scalars['String'];
};


export type QueryGetIntentForEventArgs = {
  id: Scalars['String'];
};


export type QueryGetTeamArgs = {
  id: Scalars['String'];
};


export enum MatchStatus {
  Starting = 'STARTING',
  InProgress = 'IN_PROGRESS',
  Completed = 'COMPLETED',
  Error = 'ERROR'
}

export type MatchTeamMember = {
  __typename?: 'MatchTeamMember';
  id: Scalars['String'];
  name: Scalars['String'];
  iconUrl: Scalars['String'];
  wins: Scalars['Int'];
  kills: Scalars['Int'];
  deaths: Scalars['Int'];
};

export type TeamOrder = {
  __typename?: 'TeamOrder';
  id: Scalars['String'];
  teamId: Scalars['String'];
  status: PaymentStatus;
  amount: Scalars['Int'];
  createdAt: Scalars['String'];
};

export type Team = {
  __typename?: 'Team';
  id: Scalars['String'];
  name: Scalars['String'];
  region: Region;
  kit: WeaponKit;
  members?: Maybe<Array<TeamMember>>;
  teamDisabled?: Maybe<Scalars['Boolean']>;
  isMatchmakingEligble?: Maybe<Scalars['Boolean']>;
  currentRank?: Maybe<Scalars['Int']>;
  matchCount?: Maybe<Scalars['Int']>;
};

export type TeamInvite = {
  __typename?: 'TeamInvite';
  id: Scalars['String'];
  team: Team;
};

export type EditTeamInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  kit: WeaponKit;
};

export enum EventStatus {
  Registration = 'REGISTRATION',
  Starting = 'STARTING',
  Matchmaking = 'MATCHMAKING',
  Completed = 'COMPLETED'
}

export type EditPubTeamLobbyInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  kit: WeaponKit;
};

export type PubTeamLobby = {
  __typename?: 'PubTeamLobby';
  id: Scalars['String'];
  name: Scalars['String'];
  kit: WeaponKit;
  maxSize: Scalars['Int'];
  inMatchmaking: Scalars['Boolean'];
  inviteCode: Scalars['String'];
  leaderId: Scalars['String'];
  members?: Maybe<Array<User>>;
};

export type CustomLobby = {
  __typename?: 'CustomLobby';
  id: Scalars['String'];
  name: Scalars['String'];
  kit: WeaponKit;
  maxSize: Scalars['Int'];
  inMatchmaking: Scalars['Boolean'];
  inviteCode: Scalars['String'];
  leaderId: Scalars['String'];
  team1?: Maybe<Array<Scalars['String']>>;
  team2?: Maybe<Array<Scalars['String']>>;
  members?: Maybe<Array<User>>;
};

export enum LobbyInviteType {
  Pubteam = 'PUBTEAM',
  Custom = 'CUSTOM'
}

export enum PaymentStatus {
  Pending = 'PENDING',
  Completed = 'COMPLETED',
  Refunded = 'REFUNDED'
}

export type User = {
  __typename?: 'User';
  id: Scalars['String'];
  name: Scalars['String'];
  iconUrl: Scalars['String'];
  presence: UserPresence;
  registeredEvents?: Maybe<Array<UserRegisteredEvent>>;
  customerId?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  moderationAction?: Maybe<ModerationAction>;
  lobbyId?: Maybe<Scalars['String']>;
  pubTeamId?: Maybe<Scalars['String']>;
  currentMatchId?: Maybe<Scalars['String']>;
};

export enum Region {
  NorthAmerica = 'NORTH_AMERICA',
  Sweden = 'SWEDEN'
}

export enum WeaponKit {
  AssaultRifle = 'assault_rifle',
  Lr = 'lr',
  Sar = 'sar',
  Sap = 'sap',
  Python = 'python',
  Custom = 'custom',
  Thompson = 'thompson',
  Mp5 = 'mp5',
  M92 = 'm92',
  M39 = 'm39'
}

export type UserRegisteredEvent = {
  __typename?: 'UserRegisteredEvent';
  teamId: Scalars['String'];
  eventId: Scalars['String'];
  eventIcon: Scalars['String'];
  eventName: Scalars['String'];
};

export type SocialIntegration = {
  __typename?: 'SocialIntegration';
  steamUrl?: Maybe<Scalars['String']>;
  discordId?: Maybe<Scalars['String']>;
};

export type ModerationAction = {
  __typename?: 'ModerationAction';
  type: ModerationActionType;
  reason?: Maybe<Scalars['String']>;
};

export type EventTeamRegistationInput = {
  name: Scalars['String'];
  eventId: Scalars['String'];
  region: Region;
  paymentIntentId: Scalars['String'];
};

export enum UserPresence {
  Online = 'ONLINE',
  InMatch = 'IN_MATCH',
  Offline = 'OFFLINE'
}

export enum ModerationActionType {
  Ban = 'BAN',
  Warning = 'WARNING'
}

export type Mutation = {
  __typename?: 'Mutation';
  acceptFriendRequest?: Maybe<Scalars['Boolean']>;
  rejectFriendRequest?: Maybe<Scalars['Boolean']>;
  sendFriendRequest?: Maybe<Scalars['Boolean']>;
  registerTeamForEvent?: Maybe<Event>;
  createPubTeamLobby: PubTeamLobby;
  createLobbyInvite: LobbyInvite;
  editPubTeamLobby: PubTeamLobby;
  joinPubTeamLobbyByInvite: PubTeamLobby;
  joinPubTeamLobbyByInviteCode: PubTeamLobby;
  leavePubTeamLobby?: Maybe<Scalars['Boolean']>;
  removeMemberFromPubTeamLobby: PubTeamLobby;
  joinMatchmakingWithPubTeamLobby: PubTeamLobby;
  editTeam: Team;
  joinMatchmakingWithTeam: Team;
};


export type MutationAcceptFriendRequestArgs = {
  id: Scalars['String'];
};


export type MutationRejectFriendRequestArgs = {
  id: Scalars['String'];
};


export type MutationSendFriendRequestArgs = {
  id: Scalars['String'];
};


export type MutationRegisterTeamForEventArgs = {
  input?: Maybe<EventTeamRegistationInput>;
};


export type MutationCreatePubTeamLobbyArgs = {
  name: Scalars['String'];
};


export type MutationCreateLobbyInviteArgs = {
  id: Scalars['String'];
  targetId: Scalars['String'];
  inviteType: LobbyInviteType;
};


export type MutationEditPubTeamLobbyArgs = {
  input: EditPubTeamLobbyInput;
};


export type MutationJoinPubTeamLobbyByInviteArgs = {
  id: Scalars['String'];
};


export type MutationJoinPubTeamLobbyByInviteCodeArgs = {
  id: Scalars['String'];
};


export type MutationLeavePubTeamLobbyArgs = {
  id: Scalars['String'];
};


export type MutationRemoveMemberFromPubTeamLobbyArgs = {
  teamId: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationJoinMatchmakingWithPubTeamLobbyArgs = {
  id: Scalars['String'];
};


export type MutationEditTeamArgs = {
  input: EditTeamInput;
};


export type MutationJoinMatchmakingWithTeamArgs = {
  id: Scalars['String'];
};

export enum EventDayType {
  MatchmakingStarts = 'MATCHMAKING_STARTS',
  MatchmakingEnds = 'MATCHMAKING_ENDS'
}

export type Match = {
  __typename?: 'Match';
  id: Scalars['String'];
  map: Scalars['String'];
  rounds: Scalars['Int'];
  roundTime: Scalars['Int'];
  teams: Array<MatchTeam>;
  status: MatchStatus;
  serverIp?: Maybe<Scalars['String']>;
};

export type MatchTeam = {
  __typename?: 'MatchTeam';
  teamId: Scalars['String'];
  teamName: Scalars['String'];
  kit: WeaponKit;
  members: Array<MatchTeamMember>;
  wins: Scalars['Int'];
  kills: Scalars['Int'];
  deaths: Scalars['Int'];
};

export type PaymentIntent = {
  __typename?: 'PaymentIntent';
  clientSecret: Scalars['String'];
  intentId: Scalars['String'];
  amount: Scalars['Int'];
  charity?: Maybe<Scalars['String']>;
  charityAmount?: Maybe<Scalars['String']>;
};

export type EventFragment = (
  { __typename?: 'Event' }
  & Pick<Event, 'id' | 'teamSize' | 'availableKits' | 'status' | 'format' | 'startsAt' | 'endsAt' | 'entranceFee' | 'hostedWith' | 'prizePool' | 'name'>
);

export type LeaderboardEntryFragment = (
  { __typename?: 'LeaderboardEntry' }
  & Pick<LeaderboardEntry, 'name' | 'rank'>
);

export type MatchFragment = (
  { __typename?: 'Match' }
  & Pick<Match, 'id' | 'map' | 'rounds' | 'roundTime' | 'status' | 'serverIp'>
  & { teams: Array<(
    { __typename?: 'MatchTeam' }
    & MatchTeamFragment
  )> }
);

export type MatchTeamFragment = (
  { __typename?: 'MatchTeam' }
  & Pick<MatchTeam, 'teamId' | 'teamName' | 'kit' | 'wins' | 'kills' | 'deaths'>
  & { members: Array<(
    { __typename?: 'MatchTeamMember' }
    & MatchTeamMemberFragment
  )> }
);

export type MatchTeamMemberFragment = (
  { __typename?: 'MatchTeamMember' }
  & Pick<MatchTeamMember, 'id' | 'name' | 'iconUrl' | 'wins' | 'kills' | 'deaths'>
);

export type PaymentIntentFragment = (
  { __typename?: 'PaymentIntent' }
  & Pick<PaymentIntent, 'clientSecret' | 'intentId' | 'amount'>
);

export type PubTeamLobbyFragment = (
  { __typename?: 'PubTeamLobby' }
  & Pick<PubTeamLobby, 'id' | 'name' | 'kit' | 'maxSize' | 'inMatchmaking' | 'inviteCode' | 'leaderId'>
  & { members?: Maybe<Array<(
    { __typename?: 'User' }
    & UserFragment
  )>> }
);

export type TeamFragment = (
  { __typename?: 'Team' }
  & Pick<Team, 'id' | 'region' | 'kit' | 'name' | 'teamDisabled' | 'matchCount' | 'isMatchmakingEligble' | 'currentRank'>
  & { members?: Maybe<Array<(
    { __typename?: 'TeamMember' }
    & TeamMemberFragment
  )>> }
);

export type TeamMemberFragment = (
  { __typename?: 'TeamMember' }
  & Pick<TeamMember, 'isLeader'>
  & { user: (
    { __typename?: 'User' }
    & UserFragment
  ) }
);

export type UserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'name' | 'iconUrl' | 'presence' | 'bio' | 'lobbyId' | 'pubTeamId' | 'currentMatchId'>
  & { registeredEvents?: Maybe<Array<(
    { __typename?: 'UserRegisteredEvent' }
    & UserRegisteredEventFragment
  )>> }
);

export type UserRegisteredEventFragment = (
  { __typename?: 'UserRegisteredEvent' }
  & Pick<UserRegisteredEvent, 'teamId' | 'eventId' | 'eventIcon' | 'eventName'>
);

export type CreatePubTeamLobbyMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type CreatePubTeamLobbyMutation = (
  { __typename?: 'Mutation' }
  & { createPubTeamLobby: (
    { __typename?: 'PubTeamLobby' }
    & PubTeamLobbyFragment
  ) }
);

export type EditPubTeamLobbyMutationVariables = Exact<{
  input: EditPubTeamLobbyInput;
}>;


export type EditPubTeamLobbyMutation = (
  { __typename?: 'Mutation' }
  & { editPubTeamLobby: (
    { __typename?: 'PubTeamLobby' }
    & PubTeamLobbyFragment
  ) }
);

export type EditTeamMutationVariables = Exact<{
  input: EditTeamInput;
}>;


export type EditTeamMutation = (
  { __typename?: 'Mutation' }
  & { editTeam: (
    { __typename?: 'Team' }
    & TeamFragment
  ) }
);

export type JoinMatchmakingWithPubTeamLobbyMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type JoinMatchmakingWithPubTeamLobbyMutation = (
  { __typename?: 'Mutation' }
  & { joinMatchmakingWithPubTeamLobby: (
    { __typename?: 'PubTeamLobby' }
    & PubTeamLobbyFragment
  ) }
);

export type JoinMatchmakingWithTeamMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type JoinMatchmakingWithTeamMutation = (
  { __typename?: 'Mutation' }
  & { joinMatchmakingWithTeam: (
    { __typename?: 'Team' }
    & TeamFragment
  ) }
);

export type JoinPubTeamLobbyByInviteCodeMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type JoinPubTeamLobbyByInviteCodeMutation = (
  { __typename?: 'Mutation' }
  & { joinPubTeamLobbyByInviteCode: (
    { __typename?: 'PubTeamLobby' }
    & PubTeamLobbyFragment
  ) }
);

export type LeavePubTeamLobbyMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type LeavePubTeamLobbyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'leavePubTeamLobby'>
);

export type RegisterTeamForEventMutationVariables = Exact<{
  input: EventTeamRegistationInput;
}>;


export type RegisterTeamForEventMutation = (
  { __typename?: 'Mutation' }
  & { registerTeamForEvent?: Maybe<(
    { __typename?: 'Event' }
    & EventFragment
  )> }
);

export type GetEventsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEventsQuery = (
  { __typename?: 'Query' }
  & { getEvents?: Maybe<Array<Maybe<(
    { __typename?: 'Event' }
    & EventFragment
  )>>> }
);

export type GetIntentForEventQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetIntentForEventQuery = (
  { __typename?: 'Query' }
  & { getIntentForEvent: (
    { __typename?: 'PaymentIntent' }
    & PaymentIntentFragment
  ) }
);

export type GetLeaderboardsForEventQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetLeaderboardsForEventQuery = (
  { __typename?: 'Query' }
  & { getLeaderboardsForEvent?: Maybe<Array<Maybe<(
    { __typename?: 'LeaderboardEntry' }
    & LeaderboardEntryFragment
  )>>> }
);

export type GetMatchQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetMatchQuery = (
  { __typename?: 'Query' }
  & { getMatch: (
    { __typename?: 'Match' }
    & MatchFragment
  ) }
);

export type GetMyUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyUserQuery = (
  { __typename?: 'Query' }
  & { getMyUser: (
    { __typename?: 'User' }
    & UserFragment
  ) }
);

export type GetPubTeamLobbyQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetPubTeamLobbyQuery = (
  { __typename?: 'Query' }
  & { getPubTeamLobby: (
    { __typename?: 'PubTeamLobby' }
    & PubTeamLobbyFragment
  ) }
);

export type GetPubTeamLobbyByInviteQueryVariables = Exact<{
  inviteId: Scalars['String'];
}>;


export type GetPubTeamLobbyByInviteQuery = (
  { __typename?: 'Query' }
  & { getPubTeamLobbyByInvite: (
    { __typename?: 'PubTeamLobby' }
    & PubTeamLobbyFragment
  ) }
);

export type GetTeamQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetTeamQuery = (
  { __typename?: 'Query' }
  & { getTeam?: Maybe<(
    { __typename?: 'Team' }
    & TeamFragment
  )> }
);

export type GetUserQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetUserQuery = (
  { __typename?: 'Query' }
  & { getUser: (
    { __typename?: 'User' }
    & UserFragment
  ) }
);

export const EventFragmentDoc = gql`
    fragment Event on Event {
  id
  teamSize
  availableKits
  status
  format
  startsAt
  endsAt
  entranceFee
  hostedWith
  prizePool
  name
}
    `;
export const LeaderboardEntryFragmentDoc = gql`
    fragment LeaderboardEntry on LeaderboardEntry {
  name
  rank
}
    `;
export const MatchTeamMemberFragmentDoc = gql`
    fragment MatchTeamMember on MatchTeamMember {
  id
  name
  iconUrl
  wins
  kills
  deaths
}
    `;
export const MatchTeamFragmentDoc = gql`
    fragment MatchTeam on MatchTeam {
  teamId
  teamName
  kit
  members {
    ...MatchTeamMember
  }
  wins
  kills
  deaths
}
    ${MatchTeamMemberFragmentDoc}`;
export const MatchFragmentDoc = gql`
    fragment Match on Match {
  id
  map
  rounds
  roundTime
  teams {
    ...MatchTeam
  }
  status
  serverIp
}
    ${MatchTeamFragmentDoc}`;
export const PaymentIntentFragmentDoc = gql`
    fragment PaymentIntent on PaymentIntent {
  clientSecret
  intentId
  amount
}
    `;
export const UserRegisteredEventFragmentDoc = gql`
    fragment UserRegisteredEvent on UserRegisteredEvent {
  teamId
  eventId
  eventIcon
  eventName
}
    `;
export const UserFragmentDoc = gql`
    fragment User on User {
  id
  name
  iconUrl
  presence
  registeredEvents {
    ...UserRegisteredEvent
  }
  bio
  lobbyId
  pubTeamId
  currentMatchId
}
    ${UserRegisteredEventFragmentDoc}`;
export const PubTeamLobbyFragmentDoc = gql`
    fragment PubTeamLobby on PubTeamLobby {
  id
  name
  kit
  maxSize
  inMatchmaking
  inviteCode
  leaderId
  members {
    ...User
  }
}
    ${UserFragmentDoc}`;
export const TeamMemberFragmentDoc = gql`
    fragment TeamMember on TeamMember {
  user {
    ...User
  }
  isLeader
}
    ${UserFragmentDoc}`;
export const TeamFragmentDoc = gql`
    fragment Team on Team {
  id
  region
  kit
  name
  members {
    ...TeamMember
  }
  teamDisabled
  matchCount
  isMatchmakingEligble
  currentRank
}
    ${TeamMemberFragmentDoc}`;
export const CreatePubTeamLobbyDocument = gql`
    mutation createPubTeamLobby($name: String!) {
  createPubTeamLobby(name: $name) {
    ...PubTeamLobby
  }
}
    ${PubTeamLobbyFragmentDoc}`;
export const EditPubTeamLobbyDocument = gql`
    mutation editPubTeamLobby($input: EditPubTeamLobbyInput!) {
  editPubTeamLobby(input: $input) {
    ...PubTeamLobby
  }
}
    ${PubTeamLobbyFragmentDoc}`;
export const EditTeamDocument = gql`
    mutation editTeam($input: EditTeamInput!) {
  editTeam(input: $input) {
    ...Team
  }
}
    ${TeamFragmentDoc}`;
export const JoinMatchmakingWithPubTeamLobbyDocument = gql`
    mutation joinMatchmakingWithPubTeamLobby($id: String!) {
  joinMatchmakingWithPubTeamLobby(id: $id) {
    ...PubTeamLobby
  }
}
    ${PubTeamLobbyFragmentDoc}`;
export const JoinMatchmakingWithTeamDocument = gql`
    mutation joinMatchmakingWithTeam($id: String!) {
  joinMatchmakingWithTeam(id: $id) {
    ...Team
  }
}
    ${TeamFragmentDoc}`;
export const JoinPubTeamLobbyByInviteCodeDocument = gql`
    mutation joinPubTeamLobbyByInviteCode($id: String!) {
  joinPubTeamLobbyByInviteCode(id: $id) {
    ...PubTeamLobby
  }
}
    ${PubTeamLobbyFragmentDoc}`;
export const LeavePubTeamLobbyDocument = gql`
    mutation leavePubTeamLobby($id: String!) {
  leavePubTeamLobby(id: $id)
}
    `;
export const RegisterTeamForEventDocument = gql`
    mutation registerTeamForEvent($input: EventTeamRegistationInput!) {
  registerTeamForEvent(input: $input) {
    ...Event
  }
}
    ${EventFragmentDoc}`;
export const GetEventsDocument = gql`
    query getEvents {
  getEvents {
    ...Event
  }
}
    ${EventFragmentDoc}`;
export const GetIntentForEventDocument = gql`
    query getIntentForEvent($id: String!) {
  getIntentForEvent(id: $id) {
    ...PaymentIntent
  }
}
    ${PaymentIntentFragmentDoc}`;
export const GetLeaderboardsForEventDocument = gql`
    query getLeaderboardsForEvent($id: String!) {
  getLeaderboardsForEvent(id: $id) {
    ...LeaderboardEntry
  }
}
    ${LeaderboardEntryFragmentDoc}`;
export const GetMatchDocument = gql`
    query getMatch($id: String!) {
  getMatch(id: $id) {
    ...Match
  }
}
    ${MatchFragmentDoc}`;
export const GetMyUserDocument = gql`
    query getMyUser {
  getMyUser {
    ...User
  }
}
    ${UserFragmentDoc}`;
export const GetPubTeamLobbyDocument = gql`
    query getPubTeamLobby($id: String!) {
  getPubTeamLobby(id: $id) {
    ...PubTeamLobby
  }
}
    ${PubTeamLobbyFragmentDoc}`;
export const GetPubTeamLobbyByInviteDocument = gql`
    query getPubTeamLobbyByInvite($inviteId: String!) {
  getPubTeamLobbyByInvite(inviteId: $inviteId) {
    ...PubTeamLobby
  }
}
    ${PubTeamLobbyFragmentDoc}`;
export const GetTeamDocument = gql`
    query getTeam($id: String!) {
  getTeam(id: $id) {
    ...Team
  }
}
    ${TeamFragmentDoc}`;
export const GetUserDocument = gql`
    query getUser($id: String!) {
  getUser(id: $id) {
    ...User
  }
}
    ${UserFragmentDoc}`;

export type SdkFunctionWrapper = <T>(action: () => Promise<T>) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = sdkFunction => sdkFunction();
export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    createPubTeamLobby(variables: CreatePubTeamLobbyMutationVariables, requestHeaders?: HeadersInit): Promise<CreatePubTeamLobbyMutation> {
      return withWrapper(() => client.request<CreatePubTeamLobbyMutation>(print(CreatePubTeamLobbyDocument), variables, requestHeaders));
    },
    editPubTeamLobby(variables: EditPubTeamLobbyMutationVariables, requestHeaders?: HeadersInit): Promise<EditPubTeamLobbyMutation> {
      return withWrapper(() => client.request<EditPubTeamLobbyMutation>(print(EditPubTeamLobbyDocument), variables, requestHeaders));
    },
    editTeam(variables: EditTeamMutationVariables, requestHeaders?: HeadersInit): Promise<EditTeamMutation> {
      return withWrapper(() => client.request<EditTeamMutation>(print(EditTeamDocument), variables, requestHeaders));
    },
    joinMatchmakingWithPubTeamLobby(variables: JoinMatchmakingWithPubTeamLobbyMutationVariables, requestHeaders?: HeadersInit): Promise<JoinMatchmakingWithPubTeamLobbyMutation> {
      return withWrapper(() => client.request<JoinMatchmakingWithPubTeamLobbyMutation>(print(JoinMatchmakingWithPubTeamLobbyDocument), variables, requestHeaders));
    },
    joinMatchmakingWithTeam(variables: JoinMatchmakingWithTeamMutationVariables, requestHeaders?: HeadersInit): Promise<JoinMatchmakingWithTeamMutation> {
      return withWrapper(() => client.request<JoinMatchmakingWithTeamMutation>(print(JoinMatchmakingWithTeamDocument), variables, requestHeaders));
    },
    joinPubTeamLobbyByInviteCode(variables: JoinPubTeamLobbyByInviteCodeMutationVariables, requestHeaders?: HeadersInit): Promise<JoinPubTeamLobbyByInviteCodeMutation> {
      return withWrapper(() => client.request<JoinPubTeamLobbyByInviteCodeMutation>(print(JoinPubTeamLobbyByInviteCodeDocument), variables, requestHeaders));
    },
    leavePubTeamLobby(variables: LeavePubTeamLobbyMutationVariables, requestHeaders?: HeadersInit): Promise<LeavePubTeamLobbyMutation> {
      return withWrapper(() => client.request<LeavePubTeamLobbyMutation>(print(LeavePubTeamLobbyDocument), variables, requestHeaders));
    },
    registerTeamForEvent(variables: RegisterTeamForEventMutationVariables, requestHeaders?: HeadersInit): Promise<RegisterTeamForEventMutation> {
      return withWrapper(() => client.request<RegisterTeamForEventMutation>(print(RegisterTeamForEventDocument), variables, requestHeaders));
    },
    getEvents(variables?: GetEventsQueryVariables, requestHeaders?: HeadersInit): Promise<GetEventsQuery> {
      return withWrapper(() => client.request<GetEventsQuery>(print(GetEventsDocument), variables, requestHeaders));
    },
    getIntentForEvent(variables: GetIntentForEventQueryVariables, requestHeaders?: HeadersInit): Promise<GetIntentForEventQuery> {
      return withWrapper(() => client.request<GetIntentForEventQuery>(print(GetIntentForEventDocument), variables, requestHeaders));
    },
    getLeaderboardsForEvent(variables: GetLeaderboardsForEventQueryVariables, requestHeaders?: HeadersInit): Promise<GetLeaderboardsForEventQuery> {
      return withWrapper(() => client.request<GetLeaderboardsForEventQuery>(print(GetLeaderboardsForEventDocument), variables, requestHeaders));
    },
    getMatch(variables: GetMatchQueryVariables, requestHeaders?: HeadersInit): Promise<GetMatchQuery> {
      return withWrapper(() => client.request<GetMatchQuery>(print(GetMatchDocument), variables, requestHeaders));
    },
    getMyUser(variables?: GetMyUserQueryVariables, requestHeaders?: HeadersInit): Promise<GetMyUserQuery> {
      return withWrapper(() => client.request<GetMyUserQuery>(print(GetMyUserDocument), variables, requestHeaders));
    },
    getPubTeamLobby(variables: GetPubTeamLobbyQueryVariables, requestHeaders?: HeadersInit): Promise<GetPubTeamLobbyQuery> {
      return withWrapper(() => client.request<GetPubTeamLobbyQuery>(print(GetPubTeamLobbyDocument), variables, requestHeaders));
    },
    getPubTeamLobbyByInvite(variables: GetPubTeamLobbyByInviteQueryVariables, requestHeaders?: HeadersInit): Promise<GetPubTeamLobbyByInviteQuery> {
      return withWrapper(() => client.request<GetPubTeamLobbyByInviteQuery>(print(GetPubTeamLobbyByInviteDocument), variables, requestHeaders));
    },
    getTeam(variables: GetTeamQueryVariables, requestHeaders?: HeadersInit): Promise<GetTeamQuery> {
      return withWrapper(() => client.request<GetTeamQuery>(print(GetTeamDocument), variables, requestHeaders));
    },
    getUser(variables: GetUserQueryVariables, requestHeaders?: HeadersInit): Promise<GetUserQuery> {
      return withWrapper(() => client.request<GetUserQuery>(print(GetUserDocument), variables, requestHeaders));
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;