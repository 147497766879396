import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "utils/@reduxjs/toolkit";
import { ContainerState } from "./types";
import { Dispute, LiveMatch } from "../../../types/LiveMatch";
import {Match} from "../../../generated/graphql";

// The initial state of the LiveMatch container
export const initialState: ContainerState = {
  matches: [],
};

const liveMatchSlice = createSlice({
  name: "liveMatch",
  initialState,
  reducers: {
    fetchMatchStart(state, action: PayloadAction<string>) {},
    fetchMatchEnd(state, action: PayloadAction<Match>) {
      const match = state.matches.find(
        (match) => match.id == action.payload.id
      );
      if (match) {
        state.matches = state.matches.filter(
          (match) => match.id !== action.payload.id
        );
      }
      state.matches.push(action.payload);
    },
  },
});

export const { actions, reducer, name: sliceKey } = liveMatchSlice;
