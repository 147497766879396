import { put, takeEvery, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import {apiSdk} from "../../../utils/graphqlclient";

export function* fetchEvents() {
  const {getEvents} = yield apiSdk.getEvents()
  yield put(actions.fetchEventsEnd({events: getEvents}));
}

export function* fetchTeam(action: { payload; type: string }) {
  const {getTeam} = yield apiSdk.getTeam({id: action.payload})
  yield put(actions.fetchTeamEnd(getTeam))
}

export function* eventsSaga() {
  yield takeEvery(actions.fetchEventsStart.type, fetchEvents);
  yield takeEvery(actions.fetchTeamStart.type, fetchTeam)
}
