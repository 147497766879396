/**
 *
 * LiveMatch
 *
 */

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import { actions, reducer, sliceKey } from "./slice";
import { actions as notificationsActions } from "../../containers/Notifications/slice";
import { selectLiveMatchById } from "./selectors";
import { liveMatchSaga } from "./saga";
import { useParams } from "react-router-dom";
import { colors } from "../../../utils/colors";
import Tooltip from "../../components/Tooltip";
import { selectHasNotificationPermission } from "../Notifications/selectors";
import {MatchStatus} from "../../../generated/graphql";

interface Props {}

export function LiveMatch(props: Props) {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: liveMatchSaga });
  const { id } = useParams();

  const liveMatchFn = useSelector(selectLiveMatchById);
  const hasNotificationPermission = useSelector(
    selectHasNotificationPermission
  );
  const liveMatch = liveMatchFn(id);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.fetchMatchStart(id));
    dispatch(notificationsActions.checkNotificationPermissions());
  }, [id]);

  let content;

  if (liveMatch) {
    switch (liveMatch.status) {
      case MatchStatus.Starting:
        content = (
        <>
          <div
            className="mx-auto mt-8 p-8 content-modal"
            style={{width: 560, height: 140}}
          >
            <div className="flex items-center justify-center">
              <div className="mr-3">
                <svg
                  viewBox="0 0 20 20"
                  fill={colors.orange}
                  className="h-20 w-20"
                >
                  <path
                    fillRule="evenodd"
                    d="M2 5a2 2 0 012-2h12a2 2 0 012 2v2a2 2 0 01-2 2H4a2 2 0 01-2-2V5zm14 1a1 1 0 11-2 0 1 1 0 012 0zM2 13a2 2 0 012-2h12a2 2 0 012 2v2a2 2 0 01-2 2H4a2 2 0 01-2-2v-2zm14 1a1 1 0 11-2 0 1 1 0 012 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <div>
                <div
                  style={{color: "rgba(255, 255, 255, 0.8)", fontSize: 28}}
                >
                  Starting Server
                </div>
                <div
                  style={{color: "rgba(255, 255, 255, 0.6)", fontSize: 15}}
                >
                  We’re launching a new server just for your match. Server IP will be available in 2-6 minutes.
                </div>
              </div>
            </div>
          </div>
        </>
      )
        ;
        break;
      case MatchStatus.Completed:
        content = (
          <>
            <div
              className="mx-auto mt-8 p-8 content-modal"
              style={{ width: 560, height: 140 }}
            >
              <div className="flex items-center justify-center">
                <div className="mr-3">
                  <svg
                    viewBox="0 0 20 20"
                    fill={colors.orange}
                    className="h-20 w-20"
                  >
                    <path
                      fillRule="evenodd"
                      d="M2 5a2 2 0 012-2h12a2 2 0 012 2v2a2 2 0 01-2 2H4a2 2 0 01-2-2V5zm14 1a1 1 0 11-2 0 1 1 0 012 0zM2 13a2 2 0 012-2h12a2 2 0 012 2v2a2 2 0 01-2 2H4a2 2 0 01-2-2v-2zm14 1a1 1 0 11-2 0 1 1 0 012 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <div>
                  <div
                    style={{ color: "rgba(255, 255, 255, 0.8)", fontSize: 28 }}
                  >
                    Match Completed
                  </div>
                  <div
                    style={{ color: "rgba(255, 255, 255, 0.6)", fontSize: 15 }}
                  >
                    This match is now complete! To join another match, open your team dashboard and requeue for matchmaking.
                  </div>
                </div>
              </div>
            </div>
          </>
        );
        break;
      case MatchStatus.InProgress:
        content = (
          <>
            <div
              className="mx-auto mt-8 p-8 content-modal"
              style={{ width: 560, height: 140 }}
            >
              <div className="flex items-center justify-center">
                <div className="mr-3">
                  <svg
                    viewBox="0 0 20 20"
                    fill={colors.orange}
                    className="h-20 w-20"
                  >
                    <path
                      fillRule="evenodd"
                      d="M2 5a2 2 0 012-2h12a2 2 0 012 2v2a2 2 0 01-2 2H4a2 2 0 01-2-2V5zm14 1a1 1 0 11-2 0 1 1 0 012 0zM2 13a2 2 0 012-2h12a2 2 0 012 2v2a2 2 0 01-2 2H4a2 2 0 01-2-2v-2zm14 1a1 1 0 11-2 0 1 1 0 012 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <div>
                  <div
                    style={{ color: "rgba(255, 255, 255, 0.8)", fontSize: 28 }}
                  >
                    Server Ready
                  </div>
                  <div
                    style={{ color: "rgba(255, 255, 255, 0.6)", fontSize: 14 }}
                  >
                    connect {liveMatch.serverIp}
                  </div>
                  <div
                    style={{ color: "rgba(255, 255, 255, 0.6)", fontSize: 14 }}
                  >
                    Paste the server address above in your Rust console.
                  </div>
                </div>
              </div>
            </div>
          </>
        );
        break;
      case MatchStatus.Error:
        content = (
          <>
            <div
              className="mx-auto mt-8 p-8 content-modal"
              style={{ width: 560, height: 140 }}
            >
              <div className="flex items-center justify-center">
                <div className="mr-3">
                  <svg viewBox="0 0 20 20" fill="#A83333" className="h-20 w-20">
                    <path
                      fillRule="evenodd"
                      d="M2 5a2 2 0 012-2h12a2 2 0 012 2v2a2 2 0 01-2 2H4a2 2 0 01-2-2V5zm14 1a1 1 0 11-2 0 1 1 0 012 0zM2 13a2 2 0 012-2h12a2 2 0 012 2v2a2 2 0 01-2 2H4a2 2 0 01-2-2v-2zm14 1a1 1 0 11-2 0 1 1 0 012 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <div>
                  <div
                    style={{ color: "rgba(255, 255, 255, 0.8)", fontSize: 28 }}
                  >
                    Match Cancelled
                  </div>
                  <div
                    style={{ color: "rgba(255, 255, 255, 0.6)", fontSize: 14 }}
                  >
                    The matchmaking server signalled for this match to be
                    cancelled. Results from this match will not affect
                    leaderboards.
                  </div>
                </div>
              </div>
            </div>
          </>
        );
        break;
    }
  }

  return (
    <>
      <div
        className="h-screen w-full overflow-y-auto"
        style={{ background: colors.mainContentBackground }}
      >
        {liveMatch ? (
          <>
            {content}
            {/*{liveMatch.status ==*/}
            {/*  (MatchStatus.Starting || MatchStatus.InProgress) &&*/}
            {/*  !hasNotificationPermission && <NotificationBanner />}*/}
            {/*{liveMatch.status === MatchStatus.COMPLETED && (*/}
            {/*  <LiveMatchDisputes matchId={id} />*/}
            {/*)}*/}
            {/*{liveMatch.status === MatchStatus.COMPLETED && (*/}
            {/*  <MatchResults matchId={id} />*/}
            {/*)}*/}
            <div className="flex items-center justify-center mt-6 mb-8">
              <div
                style={{ width: 270, height: 410 }}
                className="content-modal p-8 mr-5"
              >
                <div
                  style={{ color: "rgba(255, 255, 255, 0.8)", fontSize: 24 }}
                >
                  Match
                </div>
                <div className="mt-2">
                  <div
                    style={{ color: "rgba(255, 255, 255, 0.5)", fontSize: 12 }}
                  >
                    MAX ROUNDS
                  </div>
                  <div
                    style={{
                      fontSize: 20,
                      marginTop: -5,
                      color: "rgba(255, 255, 255, 0.8)",
                      lineHeight: "normal",
                    }}
                  >
                    {liveMatch.rounds}
                  </div>
                </div>
                <div className="mt-2">
                  <div
                    style={{ color: "rgba(255, 255, 255, 0.5)", fontSize: 12 }}
                  >
                    ROUND TIME
                  </div>
                  <div
                    style={{
                      fontSize: 20,
                      marginTop: -5,
                      color: "rgba(255, 255, 255, 0.8)",
                      lineHeight: "normal",
                    }}
                  >
                    {liveMatch.roundTime} seconds
                  </div>
                </div>
                <div className="mt-2">
                  <div
                    style={{ color: "rgba(255, 255, 255, 0.5)", fontSize: 12 }}
                  >
                    MAP
                  </div>
                  <div
                    style={{
                      fontSize: 20,
                      marginTop: -5,
                      color: "rgba(255, 255, 255, 0.8)",
                      lineHeight: "normal",
                    }}
                  >
                    {liveMatch.map}
                  </div>
                </div>
              </div>
              <div
                style={{ width: 270, height: 410 }}
                className="content-modal p-8 "
              >
                <div
                  style={{ color: "rgba(255, 255, 255, 0.8)", fontSize: 24 }}
                >
                  {liveMatch.teams[0].teamName}
                </div>
                <div className="mt-2">
                  <div
                    style={{ color: "rgba(255, 255, 255, 0.5)", fontSize: 12 }}
                  >
                    ARMOR KIT
                  </div>
                  <div
                    style={{
                      fontSize: 16,
                      marginTop: -5,
                      color: "rgba(255, 255, 255, 0.8)",
                      lineHeight: "normal",
                    }}
                  >
                    HQM
                  </div>
                </div>
                <div className="mt-2">
                  <div
                    style={{ color: "rgba(255, 255, 255, 0.5)", fontSize: 12 }}
                  >
                    WEAPON KIT
                  </div>
                  <div
                    style={{
                      fontSize: 16,
                      marginTop: -5,
                      color: "rgba(255, 255, 255, 0.8)",
                      lineHeight: "normal",
                    }}
                  >
                    {liveMatch.teams[0].kit}
                  </div>
                </div>
                <div className="flex mt-2">
                  {liveMatch.teams[0].members.map((user) => (
                    <Tooltip position="top" group={user.id}>
                      <img
                        src={user.iconUrl}
                        className="rounded-full mr-2"
                        width={30}
                        style={{ height: 30 }}
                      />
                      <span>{user.name}</span>
                    </Tooltip>
                  ))}
                </div>
                <hr
                  className="w-full my-4"
                  style={{ borderColor: "#383B40" }}
                />
                <div
                  style={{ color: "rgba(255, 255, 255, 0.8)", fontSize: 24 }}
                >
                  {liveMatch.teams[1].teamName}
                </div>
                <div className="mt-2">
                  <div
                    style={{ color: "rgba(255, 255, 255, 0.5)", fontSize: 12 }}
                  >
                    ARMOR KIT
                  </div>
                  <div
                    style={{
                      fontSize: 16,
                      marginTop: -5,
                      color: "rgba(255, 255, 255, 0.8)",
                      lineHeight: "normal",
                    }}
                  >
                    HQM
                  </div>
                </div>
                <div className="mt-2">
                  <div
                    style={{ color: "rgba(255, 255, 255, 0.5)", fontSize: 12 }}
                  >
                    WEAPON KIT
                  </div>
                  <div
                    style={{
                      fontSize: 16,
                      marginTop: -5,
                      color: "rgba(255, 255, 255, 0.8)",
                      lineHeight: "normal",
                    }}
                  >
                    {liveMatch.teams[1].kit}
                  </div>
                </div>
                <div className="flex mt-2">
                  {liveMatch.teams[1].members.map((user) => (
                    <Tooltip position="top" group={user.id}>
                      <img
                        src={user.iconUrl}
                        className="rounded-full mr-2"
                        width={30}
                        style={{ height: 30 }}
                      />
                      <span>{user.name}</span>
                    </Tooltip>
                  ))}
                </div>
              </div>
            </div>
          </>
        ) : (
          <div
            className="mx-auto mt-8 p-8 content-modal"
            style={{ width: 560, height: 140 }}
          >
            <div className="flex items-center justify-center">
              <div className="mr-3">
                <svg
                  viewBox="0 0 20 20"
                  fill={colors.orange}
                  className="h-20 w-20"
                >
                  <path
                    fillRule="evenodd"
                    d="M2 5a2 2 0 012-2h12a2 2 0 012 2v2a2 2 0 01-2 2H4a2 2 0 01-2-2V5zm14 1a1 1 0 11-2 0 1 1 0 012 0zM2 13a2 2 0 012-2h12a2 2 0 012 2v2a2 2 0 01-2 2H4a2 2 0 01-2-2v-2zm14 1a1 1 0 11-2 0 1 1 0 012 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <div>
                <div
                  style={{ color: "rgba(255, 255, 255, 0.8)", fontSize: 28 }}
                >
                  Loading Match
                </div>
                <div
                  style={{ color: "rgba(255, 255, 255, 0.6)", fontSize: 15 }}
                >
                  Fetching match details.
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
