import { put, takeEvery, call } from 'redux-saga/effects';
import { actions } from './slice';
import {apiClient, apiSdk} from "../../../utils/graphqlclient";
import {push} from "connected-react-router";

export function* fetchCurrentUser() {
  const {getMyUser} = yield apiSdk.getMyUser()
  yield put(actions.fetchCurrentUserEnd(getMyUser))
}

export function* fetchUser(action: { payload; type: string }) {
  const {getUser} = yield apiSdk.getUser({id: action.payload})
  yield put(actions.fetchUserEnd(getUser))
}

export function* fetchInitial() {
  const token = yield call([localStorage, "getItem"], "do_not_share");
  if (!!token) {
    apiClient.setHeader("Authorization", token)
    yield call(fetchCurrentUser);
  }
  yield put(actions.fetchInitialEnd(token));
}

export function* saveAuth(action: { payload: string; type: string }) {
  const token = action.payload;
  yield call(storeToken, token);
  yield call(fetchCurrentUser)
  yield put(actions.fetchInitialEnd(true));
}

export function* logout() {
  yield call(cleanAuth);
  yield put(push("/"));
}

export function* userSaga() {
  yield takeEvery(actions.fetchCurrentUserStart.type, fetchCurrentUser);
  yield takeEvery(actions.fetchUserStart.type, fetchUser);
  yield takeEvery(actions.fetchInitialStart.type, fetchInitial)
  yield takeEvery(actions.storeAuth.type, saveAuth)
}

async function storeToken(token) {
  apiClient.setHeader("Authorization", token)
  try {
    await localStorage.setItem("do_not_share", token);
  } catch (error) {
    console.log("AsyncStorage error during token store:", error);
  }
}

async function cleanAuth() {
  apiClient.setHeader("Authorization", "")
  try {
    await localStorage.removeItem("do_not_share");
  } catch (error) {
    console.log("AsyncStorage error during token clear:", error);
  }
}
