import { EditIcon } from "../../../../components/Icons/EditIcon";
import React from "react";
import { PubTeamLobby } from "../../../../../generated/graphql";

interface Props {
  lobby: PubTeamLobby;
}

export function EditPubTeamRegion({ lobby }: Props) {
  return (
    <div className="flex bg-secondary py-2 px-4 items-center rounded">
      <div className="flex-grow">
        <div className="leading-none text-sm color-white-40 mb-1">
          SERVER REGION
        </div>
        <div className="leading-none color-white-60">North America</div>
      </div>
    </div>
  );
}
