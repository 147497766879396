import {
  DefaultItemsCenter,
  DefaultPageWrapper,
  IconWrapper,
} from "../../../../components/Main";
import React, {useEffect} from "react";
import BackgroundImg from "../../assets/steviebg.png";
import { SettingsIcon } from "../../../../components/Icons/SettingsIcon";
import { MembersIcon } from "../../../../components/Icons/MembersIcon";
import UserListItem from "../../../../components/UserListItem";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {selectEventById, selectTeamForEvent, selectTeamIdForEvent} from "../../selectors";
import {actions} from "../../slice"
import {CalendarIcon} from "../../../../components/Icons/CalendarIcon";
import {EditTeamName} from "./EditTeamName";
import {EditTeamKit} from "./EditTeamKit";
import {EditTeamRegion} from "./EditTeamRegion";
import {MatchmakingIcon} from "../../../../components/Icons/MatchmakingIcon";
import {HistoryIcon} from "../../../../components/Icons/HistoryIcon";
import {LeaderboardIcon} from "../../../../components/Icons/LeaderboardIcon";
import spacetime from "spacetime";
import {TeamMatchmaking} from "./TeamMatchmaking";
import EventLeaderboard from "./EventLeaderboard";

export default function TeamDashboard() {
  const { id } = useParams();
  const team = useSelector(selectTeamForEvent)(id)
  const event = useSelector(selectEventById)(id)
  const teamId = useSelector(selectTeamIdForEvent)(id)
  const dispatch = useDispatch()

  useEffect(() => {
    if (teamId) {
      dispatch(actions.fetchTeamStart(teamId))
    }
  }, [teamId])

  if (!team) {
    return <DefaultPageWrapper />
  }

  const startsAt = new Date(event?.startsAt || '')

  return (
    <DefaultPageWrapper>
      <DefaultItemsCenter className="mt-20">
        <div className="flex">
          <div className="mr-4">
            <div className="flex">
              <img src={BackgroundImg} style={{ width: 460, height: 170 }} />
              <div className="content-modal py-2 pl-4 -ml-40">
                <div className="flex items-center">
                  <IconWrapper>
                    <CalendarIcon className="h-6 w-6" />
                  </IconWrapper>
                  <div className="ml-2 color-white-30 mr-10">EVENT CALENDAR</div>
                </div>
                <div className="flex items-center mt-3">
                  <div className="p-2 rounded-md mr-4">
                    <div className="color-white-60">{spacetime(startsAt).format('{day}, {month-short} {date-ordinal}')}</div>
                    <div className="color-white-70 mb-1">{spacetime(startsAt).format('time')}</div>
                    <div className="color-white-30">EVENT STARTS</div>
                  </div>
                </div>
              </div>
            </div>

            <EventLeaderboard eventId={event?.id || ''} team={team} />

            <div className="content-modal p-5 mt-4">
              <div className="flex items-center">
                <IconWrapper>
                  <HistoryIcon className="h-6 w-6" />
                </IconWrapper>
                <div className="ml-2 text-lg color-white-60">Match History</div>
              </div>
              <div className="mt-3">
                <div className="flex bg-secondary py-2 px-4 items-center rounded">
                  <div className="flex-grow color-white-50">
                    <div>
                      You haven't played any matches in this event yet
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="content-modal p-5 mb-4" style={{ width: 400 }}>
              <div className="flex items-center">
                <IconWrapper>
                  <MembersIcon className="h-6 w-6" />
                </IconWrapper>
                <div className="ml-2 text-lg color-white-60">Team Roster</div>
              </div>
              <div className="mt-3">
                {team.members?.map((member) => (
                  <UserListItem user={member.user!} />
                ))}
              </div>
            </div>

            <div className="content-modal p-5 mt-4">
              <div className="flex items-center mb-3">
                <IconWrapper>
                  <SettingsIcon className="h-6 w-6" />
                </IconWrapper>
                <div className="ml-2 text-lg color-white-60">Team Settings</div>
              </div>
              <EditTeamName team={team} />
              <div className="my-2" />
              <EditTeamKit team={team} />
              <div className="my-2" />
              <EditTeamRegion team={team} />
            </div>

            <TeamMatchmaking team={team} />
          </div>
        </div>
      </DefaultItemsCenter>
    </DefaultPageWrapper>
  );
}
