import { takeEvery } from 'redux-saga/effects';
import { actions } from './slice';

const notificationSound = document.getElementById('notification-sound') as HTMLAudioElement;

export function* sendNotification(action: { payload: {title: string; body: string}; type: string }) {
  const {title, body} = action.payload
  yield sendNotificationFn(title, body)
}

export function* notificationsSaga() {
  yield takeEvery(actions.sendNotification.type, sendNotification);
}

function sendNotificationFn(title: string, body: string) {
  notificationSound.play().catch(() => true);
  return new Notification(title, {body});
}