/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from "react";
import { Switch, Route } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Fathom from "fathom-react";

import { GlobalStyle } from "styles/global-styles";

import { Notifications } from "./containers/Notifications";
import { EventSignup } from "./containers/Events/components/Signup/Signup";
import { Events } from "./containers/Events";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Profile from "./containers/User/components/Profile";
import { User } from "./containers/User";
import { Lobbies } from "./containers/Lobbies";
import PubDashboard from "./containers/Lobbies/components/Dashboard/Dashboard";
import Sidebar from "./components/Sidebar";
import TeamDashboard from "./containers/Events/components/Dashboard";
import {PrivateRoute} from "./routes";
import Auth from "./containers/User/components/Auth";
import {DefaultPageWrapper} from "./components/Main";
import Landing from "./containers/Events/components/Landing/Landing";
import HomePage from "./containers/User/components/HomePage/HomePage";
import {LiveMatch} from "./containers/LiveMatch";
import Websocket from "./components/Websocket";

const stripePromise = loadStripe(
  "pk_live_FltdD9wogb6hSF5XWZkzsaTS00VJLryMST"
);

export function App() {
  return (
    <Fathom siteId="JXKRUOJI">
      <Elements stripe={stripePromise}>
        <Helmet defaultTitle="RustLounge">
          <meta name="description" content="RustLounge" />
        </Helmet>

        <div className="flex">
          <Sidebar />
          <Switch>
            <Route exact path="/auth/:token" component={Auth} />
            <Route exact path="/event/:id/preview" component={Landing} />
            <PrivateRoute exact path="/" component={HomePage} />
            <PrivateRoute exact path="/match/:id" component={LiveMatch} />
            <PrivateRoute exact path="/event/:id/signup" component={EventSignup} />
            <PrivateRoute exact path="/event/:id" component={TeamDashboard} />
            <PrivateRoute exact path="/user/:id" component={Profile} />
            <PrivateRoute exact path="/lobbies" component={PubDashboard} />
          </Switch>
        </div>
        <GlobalStyle />
        <Notifications />
      </Elements>

      <Events />
      <User />
      <Lobbies />
      <Websocket />
    </Fathom>
  );
}
