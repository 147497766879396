/**
 *
 * Lobbies
 *
 */

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import { reducer, sliceKey, actions } from "./slice";
import { lobbiesSaga } from "./saga";
import { selectCurrentUser } from "../User/selectors";

interface Props {}

export function Lobbies(props: Props) {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: lobbiesSaga });

  const user = useSelector(selectCurrentUser);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user?.pubTeamId) {
      dispatch(actions.fetchPubLobbyStart(user.pubTeamId));
    }
  }, [user?.pubTeamId]);

  return <></>;
}
